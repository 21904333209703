(function () {
    'use strict';

    angular
        .module('papp')
        .controller('DieselHistoryNotificationController', DieselHistoryNotificationController);

    DieselHistoryNotificationController.$inject = ['$state', '$stateParams', 'DateUtils', 'Notification'];

    function DieselHistoryNotificationController($state, $stateParams, DateUtils, Notification) {
        var vm = this;
        var page = 1;
        var dateCreated = null;
        vm.notifications = [];

        vm.isFirstPage = true;
        vm.isLastPage = true;
        vm.dateCreatedInput = null;

        vm.prevPage = prevPage;
        vm.nextPage = nextPage;
        vm.search = search;
        vm.clearSearch = clearSearch;
        vm.showHistoryDetails = showHistoryDetails;
        vm.openDatePicker = openDatePicker;

        function prevPage() {
            $state.go('dieselHistoryNotification', {
                page: page - 1,
                dateCreated: (dateStart ? DateUtils.convertLocalDateToServer(dateStart) : null)
            });
        }

        function nextPage() {
            $state.go('dieselHistoryNotification', {
                page: page + 1,
                dateCreated: (dateStart ? DateUtils.convertLocalDateToServer(dateStart) : null)
            });
        }

        function search() {
            $state.go('dieselHistoryNotification', {
                page: 1,
                dateCreated: (vm.dateStartInput ? DateUtils.convertLocalDateToServer(vm.dateStartInput) : null)
            });
        }

        function clearSearch() {
            vm.dateCreatedInput = null;

            $state.go('dieselHistoryNotification', {
                page: 1,
                dateCreated: null
            });
        }

//        function showParking(parking) {
//            $state.go('parkingDetails', {
//                id: parking.id
//            });
//        }

        page = parseInt($stateParams.page, 10);
        if (!page) {
            page = 1;
        }

        if ($stateParams.dateCreated)
            dateStart = new Date($stateParams.dateStart);

        vm.dateCreatedInput = dateCreated;

        vm.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };

        vm.popupDatePicker = {
            opened: false
        };

        function openDatePicker() {
            vm.popupDatePicker.opened = true;
        }

        function showHistoryDetails(notification) {
            $state.go('notificationHistoryDetails', {
                id: notification.id
            });
        }

        Notification.notifications.getNotifications({
            topic: 'DIESEL',
            page: page,
            dateCreated: (dateCreated ? DateUtils.convertLocalDateToServer(dateCreated) : null)
        }, function (result) {
            vm.notifications = result;
            vm.isFirstPage = page <= 1;

            if (result.page * result.itemsPerPage < result.total) {
                vm.isLastPage = false;
            }
        });
    }
})();
