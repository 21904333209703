(function () {
    "use strict";

    angular.module("papp").config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider.state("customer", {
            parent: "app",
            url: "/customer?page&plateNumber&email",
            data: {
                authorities: ["bilioslo-admin", "bilioslo-admin-lese"],
            },
            views: {
                "content@": {
                    templateUrl: "app/customer/customer.html",
                    controller: "CustomerController",
                    controllerAs: "vm",
                },
            },
        });
    }
})();
