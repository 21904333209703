(function() {
    'use strict';

    angular
        .module('papp')
        .factory('Customer', Customer);

    Customer.$inject = ['$resource', 'URL_BIL_I_OSLO_SERVICE'];

    function Customer ($resource, URL_BIL_I_OSLO_SERVICE) {
        var service = {};

        service.getById = $resource(URL_BIL_I_OSLO_SERVICE + 'api/Admin/Customer/:id', {}, {
            'query': {method: 'GET', isArray: false},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'delete':{ method:'DELETE'}
        });
        
        service.getByIdAccessRequest = $resource(URL_BIL_I_OSLO_SERVICE + 'api/Admin/Customer/:id/AccessRequest', {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });

        service.updateToAnonomizedRequest = $resource(URL_BIL_I_OSLO_SERVICE + 'api/Admin/Customer/:id/Anonymize',  {id:'@id'}, {
            'update': { method: 'PUT' }
        });


        return service;
    }

})();
