(function () {
    "use strict";

    angular.module("papp").config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider.state("home", {
            parent: "app",
            url: "/",
            data: {
                authorities: [
                    "bilioslo-admin",
                    "bilioslo-admin-lese",
                    "bilioslo-admin-dieselvarsel",
                    "bilioslo-admin-veivarsel",
                ],
            },
            views: {
                "content@": {
                    templateUrl: "app/home/home.html",
                    controller: "HomeController",
                    controllerAs: "vm",
                },
            },
        });
    }
})();
