(function() {
    'use strict';

    angular
        .module('papp')
        .factory('Config', Config);

    Config.$inject = ['$resource', 'URL_BIL_I_OSLO_SERVICE'];

    function Config ($resource, URL_BIL_I_OSLO_SERVICE) {
        return $resource(URL_BIL_I_OSLO_SERVICE + 'api/Admin/Config', {}, 
        {
            'update': { method: 'PUT' }
        });
    }
})();
