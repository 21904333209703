(function () {
    "use strict";

    angular.module("papp").factory("DateUtils", DateUtils);

    DateUtils.$inject = ["$filter"];

    function DateUtils($filter) {
        var service = {
            convertDateTimeFromServer: convertDateTimeFromServer,
            convertLocalDateFromServer: convertLocalDateFromServer,
            convertLocalDateTimeFromServer: convertLocalDateTimeFromServer,
            convertLocalDateToServer: convertLocalDateToServer,
            convertLocalDateTimeToServer: convertLocalDateTimeToServer,
            dateformat: dateformat,
        };

        return service;

        function convertDateTimeFromServer(date) {
            if (date) {
                return new Date(date);
            } else {
                return null;
            }
        }

        function convertLocalDateFromServer(date) {
            if (date) {
                var dateString = date.split(".");
                return new Date(
                    "20" + dateString[2],
                    dateString[1] - 1,
                    dateString[0]
                );
            }
            return null;
        }

        function convertLocalDateTimeFromServer(date) {
            if (date) {
                var timeDateSplit = date.split(" ");
                var dateString = timeDateSplit[0].split(".");
                var timeString = timeDateSplit[1].split(":");
                return new Date(
                    "20" + dateString[2],
                    dateString[1] - 1,
                    dateString[0],
                    timeString[0],
                    timeString[1]
                );
            }
            return null;
        }

        function convertLocalDateToServer(date) {
            if (date) {
                return $filter("date")(date, "yyyy-MM-dd");
            } else {
                return null;
            }
        }

        function convertLocalDateTimeToServer(date, hour, minute) {
            if (date) {
                date.setHours(hour);
                date.setMinutes(minute);
                return $filter("date")(date, "yyyy-MM-dd'T'HH:mm:ss");
            }
            return null;
        }

        function dateformat() {
            return "dd.MM.YY";
        }
    }
})();
