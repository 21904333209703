(function () {
    "use strict";

    angular.module("papp").controller("LoginController", LoginController);

    LoginController.$inject = [
        "$scope",
        "Principal",
        "Auth",
        "ONELOGIN_SUBDOMAIN",
        "$sce",
        "$timeout",
    ];

    function LoginController(
        $scope,
        Principal,
        Auth,
        ONELOGIN_SUBDOMAIN,
        $sce,
        $timeout
    ) {
        var vm = this;
        var mgr = Auth.mgr;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = login;
        vm.logout = logout;
        vm.showLogin = true;
        vm.oneloginframe = "";
        vm.isLoggingIn = window.location.search.startsWith("?code");

        if (vm.isLoggingIn) {
            $timeout(function () {
                vm.isLoggingIn = false;
            }, 5000);
        }

        $scope.$on("authenticationComplete", function (evt, data) {
            console.log(
                "Broadcast received: authenticationComplete with " + data
            );
            getAccount(data);
        });

        function login(event) {
            vm.authenticationError = false;
            vm.authenticationErrorMailTo = false;
            event.preventDefault();

            mgr.signinRedirect()
                .then(function () {})
                .catch(function (err) {
                    console.log(err);
                });
        }

        getAccount();

        function logout() {
            vm.oneloginframe = $sce.trustAsResourceUrl(
                "https://" + ONELOGIN_SUBDOMAIN + ".onelogin.com/oidc/2/logout"
            );
            vm.showLogin = true;
            vm.authenticationError = false;
        }

        function getAccount(user) {
            var params = new URLSearchParams(window.location.search);
            vm.authenticationErrorMessage = params.get("error_description");
            if (vm.authenticationErrorMessage) {
                vm.authenticationError = true;
                vm.showLogin = false;
            } else {
                vm.authenticationError = false;
                vm.account = user;
                vm.isAuthenticated = Principal.isAuthenticated;
            }
        }
    }
})();
