(function () {
    'use strict';

    angular
        .module('papp')
        .filter('langcodeToText', langcodeToText);

    function langcodeToText() {
        return langcodeToTextFilter;

        function langcodeToTextFilter(langcode) {
            var languages = {"nb":"Norsk", "en":"Engelsk"};
            return languages[langcode];
        }
    }
})();
