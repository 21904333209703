(function () {
    'use strict';

    angular
        .module('papp')
        .controller('DieselSendNotificationController', DieselSendNotificationController);

    DieselSendNotificationController.$inject = ['$http', 'Notification', 'NotificationMessage', 'DateUtils', '$uibModal', 'URL_BIL_I_OSLO_SERVICE'];

    function DieselSendNotificationController($http, Notification, NotificationMessage, DateUtils, $uibModal, URL_BIL_I_OSLO_SERVICE) {
        var vm = this;

        vm.loadAll = function () {
            vm.dieselMessages = Notification.dieselMessages.getDieselMessages();
            vm.activeDieselBan = Notification.activeDieselBan.getActiveDieselBan();

            vm.endOfMessageNo = {};
            vm.endOfMessageEn = {};
            vm.endOfMessageNo.START = NotificationMessage.createEndOfMessageStartNo(null, null, null);
            vm.endOfMessageEn.START = NotificationMessage.createEndOfMessageStartEn(null, null, null);
            vm.endOfMessageNo.STOP = NotificationMessage.createEndOfMessageStopNo(null, null, null);
            vm.endOfMessageEn.STOP = NotificationMessage.createEndOfMessageStopEn(null, null, null);

            vm.endOfBoardFirstMessageNo = {};
            vm.endOfBoardFirstMessageEn = {};
            vm.endOfBoardFirstMessageNo.START = NotificationMessage.createEndOfBoardFirstMessageStartNo(null, null, null);
            vm.endOfBoardFirstMessageEn.START = NotificationMessage.createEndOfBoardFirstMessageStartEn(null, null, null);
            vm.endOfBoardFirstMessageNo.STOP = NotificationMessage.createEndOfBoardFirstMessageStopNo(null, null, null);
            vm.endOfBoardFirstMessageEn.STOP = NotificationMessage.createEndOfBoardFirstMessageStopEn(null, null, null);
        };
        vm.loadAll();

        vm.changeDate = changeDate;
        vm.openDatePickerFrom = openDatePickerFrom;
        vm.openDatePickerTo = openDatePickerTo;
        vm.sendDiesel = sendDiesel;
        vm.submit = submit;

        function openDatePickerFrom(message) {
            message.isFromPickerOpen = true;
        }
        function openDatePickerTo(message) {
            message.isToPickerOpen = true;
        }

        function changeDate(message) {
            clearErrorMessages();
            if (isInputTooHigh(message))
                return;

            switch(message.type) {
                case 'START':
                    changeDateStart(message)
                    break;
                case 'STOP':
                    changeDateStop(message)
                    break;
            }
        }

        function changeDateStart(message) {
            vm.endOfMessageNo.START = NotificationMessage.createEndOfMessageStartNo(message.fromDate, message.fromHour, message.fromMin);
            vm.endOfMessageEn.START = NotificationMessage.createEndOfMessageStartEn(message.fromDate, message.fromHour, message.fromMin);
            vm.endOfBoardFirstMessageNo.START = NotificationMessage.createEndOfBoardFirstMessageStartNo(message.fromDate, message.fromHour, message.fromMin);
            vm.endOfBoardFirstMessageEn.START = NotificationMessage.createEndOfBoardFirstMessageStartEn(message.fromDate, message.fromHour, message.fromMin);

        }
        function changeDateStop(message) {
            vm.endOfMessageNo.STOP = NotificationMessage.createEndOfMessageStopNo(message.fromDate, message.fromHour, message.fromMin);
            vm.endOfMessageEn.STOP = NotificationMessage.createEndOfMessageStopEn(message.fromDate, message.fromHour, message.fromMin);
            vm.endOfBoardFirstMessageNo.STOP = NotificationMessage.createEndOfBoardFirstMessageStopNo(message.fromDate, message.fromHour, message.fromMin);
            vm.endOfBoardFirstMessageEn.STOP = NotificationMessage.createEndOfBoardFirstMessageStopEn(message.fromDate, message.fromHour, message.fromMin);
        }

        function createCompositeBoardFirstMessageNo(message) {
            switch (message.type) {
                case "START":
                    return vm.endOfBoardFirstMessageNo[message.type] + message.messageNo.boardFirstMessage;
                case "STOP":
                    return  message.messageNo.boardFirstMessage + " " + vm.endOfBoardFirstMessageNo[message.type];
            }
        }

        function createCompositeBoardFirstMessageEn(message) {
            switch (message.type) {
                case "START":
                    return vm.endOfBoardFirstMessageEn[message.type] + message.messageEn.boardFirstMessage;
                case "STOP":
                    return  message.messageEn.boardFirstMessage + " " + vm.endOfBoardFirstMessageEn[message.type];
            }
        }

        function submit(message, $index) {
            vm.tempMessage = message;

            if (!isCorrectInput(message))
                return;
            var modalInstance = $uibModal.open({
                  animation: true,
                  ariaLabelledBy: 'modal-title',
                  ariaDescribedBy: 'modal-body',
                  templateUrl: 'app/notification/diesel-send/modal/diesel-send-modal.html',
                  controller: 'DieselSendModalController',
                  controllerAs: 'vm',
                  resolve: {
                    message: function () {
                      message.fromDateTime = message.fromDate;
                      message.fromDateTime.setHours(message.fromHour);
                      message.fromDateTime.setMinutes(message.fromMin);
                      message.messageNo.pushMessageModal = message.messageNo.pushMessage + " " + vm.endOfMessageNo[message.type];
                      message.messageEn.pushMessageModal = message.messageEn.pushMessage + " " + vm.endOfMessageEn[message.type];
                      message.messageNo.boardFirstMessageModal = createCompositeBoardFirstMessageNo(message)
                      message.messageEn.boardFirstMessageModal = createCompositeBoardFirstMessageEn(message)
                      return message;
                    }
                  }
            });

            modalInstance.result.then(function (message) {
                    console.log("Confirmed sending diesel-ban with type " + message.type)
                    sendDiesel(message, $index)
                }, function () {
                    console.log("Canceled sending of diesel-ban")
                });

        }

        function sendDiesel(message, $index) {
            clearErrorMessages();
            message.error = "Sender...";

            var messageNo = {}
            var messageEn = {}
            messageNo.pushMessage = message.messageNo.pushMessage + " " + vm.endOfMessageNo[message.type];
            messageEn.pushMessage = message.messageEn.pushMessage + " " + vm.endOfMessageEn[message.type];
            messageNo.boardTitle = message.messageNo.boardTitle;
            messageEn.boardTitle = message.messageEn.boardTitle;
            messageNo.boardFirstMessage = createCompositeBoardFirstMessageNo(message)
            messageEn.boardFirstMessage = createCompositeBoardFirstMessageEn(message)
            messageNo.boardSecondMessage = message.messageNo.boardSecondMessage;
            messageEn.boardSecondMessage = message.messageEn.boardSecondMessage;
            messageNo.boardUrl = message.messageNo.boardUrl;
            messageEn.boardUrl = message.messageEn.boardUrl;

            return $http.post(URL_BIL_I_OSLO_SERVICE + 'api/Admin/SimpleNotificationService', {
                topic: "DIESEL",
                type: message.type,
                topicTypeId: message.snsTopicTypeId,
                validFrom: DateUtils.convertLocalDateTimeToServer (message.fromDate, message.fromHour, message.fromMin),
                messageNo: messageNo,
                messageEn: messageEn,
            }).success(function (response) {
                console.log('successfully sent notification about diesel');
                message.error = "Dieselforbud sendt!";
                return response;
            }).error(function (e, status) {
                if (status == 403) {
                    message.error = "Mangler rettigheter...";
                } else {
                    message.error = "Sending feilet!";
                }
                console.log('Sending of notification failed');
            }).finally(function() {
                vm.activeDieselBan = Notification.activeDieselBan.getActiveDieselBan();
            });
        }

        function isCorrectInput(message) {
            clearErrorMessages()
            if (isInputNull(message) || isInputTooHigh(message) || isInputToLow(message))
                return false;

            switch(message.type) {
                case 'START':
                    return isCorrectDateStart(message);
                case 'STOP':
                    return isCorrectDateStop(message);
            }
        }

        function isCorrectDateStart(message) {

            var tomorrow = new Date()
            tomorrow.setDate(tomorrow.getDate()+1)
            tomorrow.setSeconds(tomorrow.getSeconds()-10)
            if (message.fromDate < tomorrow ) {
                message.error = "Dieselforbud må settes minst 24 timer før";
                return false
            }
            var inAMonth = new Date()
            inAMonth.setMonth(inAMonth.getMonth()+1)
            if (message.fromDate > inAMonth ) {
                message.error = "Dieselforbud må settes innen en måned";
                return false
            }
            return true;
        }

        function isCorrectDateStop(message) {
            var now = new Date()
            if (message.fromDate < now ) {
                message.error = "Dato må være fram i tid";
                return false
            }
            var inAMonth = new Date()
            inAMonth.setMonth(inAMonth.getMonth()+1)
            if (message.fromDate > inAMonth ) {
                message.error = "Må stoppes innen en måned";
                return false
            }
            return true;
        }

        function isInputNull(message) {
            if (message.fromDate == null) {
                message.error = "Mangler dato...";
                return true;
            }
            if (message.fromHour == null || message.fromHour == "") {
                message.error = "Mangler tid i timer";
                return true;
            }
            if (message.fromMin == null || message.fromMin == "") {
                message.error = "Mangler tid i minutter";
                return true;
            }
            return false;
        }

        function isInputTooHigh(message) {
            if (message.fromHour > 23 || isNotDigit(message.fromHour)) {
                message.error = "Gyldige verdier for timer er: 00-23";
                return true;
            }
            if (message.fromMin > 59 || isNotDigit(message.fromMin)) {
                message.error = "Gyldige verdier for minutter er: 00-59";
                return true;
            }
            if (message.fromHour != null && message.fromHour.length > 2) {
                message.error = "Timer skrives med to tall.";
                return true;
            }
            if (message.fromMin != null && message.fromHour.length > 2) {
                message.error = "Minutter skrives med to tall.";
                return true;
            }
            return false;
        }

        function isInputToLow(message) {
            if (message.fromHour < 0 || isNotDigit(message.fromHour)) {
                message.error = "Gyldige verdier for timer er: 00-23";
                return true;
            }
            if (message.fromMin < 0 || isNotDigit(message.fromMin)) {
                message.error = "Gyldige verdier for minutter er: 00-59";
                return true;
            }
        }

        function isNotDigit(number){
            return (number != null && number != "") && ! /^\d+$/.test(number)
        }

        function clearErrorMessages() {
            vm.dieselMessages[0].error = null;
            vm.dieselMessages[1].error = null;
        }
    }
})();
