(function () {
    "use strict";

    angular
        .module("papp")
        .controller(
            "DieselEditNotificationController",
            DieselEditNotificationController
        );

    DieselEditNotificationController.$inject = [
        "$http",
        "Notification",
        "NotificationMessage",
        "Principal",
        "URL_BIL_I_OSLO_SERVICE",
    ];

    function DieselEditNotificationController(
        $http,
        Notification,
        NotificationMessage,
        Principal,
        URL_BIL_I_OSLO_SERVICE
    ) {
        var vm = this;

        vm.loadAll = function () {
            vm.dieselMessages = Notification.dieselMessages.getDieselMessages();

            vm.endOfMessageStartNo =
                NotificationMessage.createEndOfComposedMessageStartNo();
            vm.endOfMessageStartEn =
                NotificationMessage.createEndOfComposedMessageStartEn();
            vm.endOfMessageStopNo =
                NotificationMessage.createEndOfComposedMessageStopNo();
            vm.endOfMessageStopEn =
                NotificationMessage.createEndOfComposedMessageStopEn();

            vm.endOfBoardFirstMessageNo = {};
            vm.endOfBoardFirstMessageEn = {};
            vm.endOfBoardFirstMessageNo.START =
                NotificationMessage.createEndOfBoardFirstMessageStartNo(
                    null,
                    null,
                    null
                );
            vm.endOfBoardFirstMessageEn.START =
                NotificationMessage.createEndOfBoardFirstMessageStartEn(
                    null,
                    null,
                    null
                );
            vm.endOfBoardFirstMessageNo.STOP =
                NotificationMessage.createEndOfBoardFirstMessageStopNo(
                    null,
                    null,
                    null
                );
            vm.endOfBoardFirstMessageEn.STOP =
                NotificationMessage.createEndOfBoardFirstMessageStopEn(
                    null,
                    null,
                    null
                );
        };
        vm.loadAll();

        vm.makeEditable = makeEditable;
        vm.cancelMessage = cancelMessage;
        vm.saveMessage = saveMessage;
        vm.show = show;

        function show(message) {
            message.show = !message.show;
        }

        function makeEditable(messageLangKey, parameter) {
            clearErrorMessages();
            if (isEditMode()) return;

            Principal.hasAuthority("bilioslo-admin-dieselvarsel").then(
                function (result) {
                    if (result) {
                        switch (parameter) {
                            case "PUSH_MESSAGE":
                                vm.messageTemp = messageLangKey.pushMessage;
                                messageLangKey.isEditable.pushMessage = true;
                                break;
                            case "BOARD_TITLE":
                                vm.messageTemp = messageLangKey.boardTitle;
                                messageLangKey.isEditable.boardTitle = true;
                                break;
                            case "BOARD_FIRST_MESSAGE":
                                vm.messageTemp =
                                    messageLangKey.boardFirstMessage;
                                messageLangKey.isEditable.boardFirstMessage = true;
                                break;
                            case "BOARD_SECOND_MESSAGE":
                                vm.messageTemp =
                                    messageLangKey.boardSecondMessage;
                                messageLangKey.isEditable.boardSecondMessage = true;
                                break;
                            case "BOARD_URL":
                                vm.messageTemp = messageLangKey.boardUrl;
                                messageLangKey.isEditable.boardUrl = true;
                                break;
                        }
                        return;
                    }
                }
            );
        }

        function cancelMessage(messageLangKey, parameter) {
            switch (parameter) {
                case "PUSH_MESSAGE":
                    messageLangKey.pushMessage = vm.messageTemp;
                    break;
                case "BOARD_TITLE":
                    messageLangKey.boardTitle = vm.messageTemp;
                    break;
                case "BOARD_FIRST_MESSAGE":
                    messageLangKey.boardFirstMessage = vm.messageTemp;
                    break;
                case "BOARD_SECOND_MESSAGE":
                    messageLangKey.boardSecondMessage = vm.messageTemp;
                    break;
                case "BOARD_URL":
                    messageLangKey.boardUrl = vm.messageTemp;
                    break;
            }
            turnOffEditMode();
        }

        function saveMessage(messageLangKey, parameter) {
            turnOffEditMode();
            turnOffErrorMessages();
            switch (parameter) {
                case "PUSH_MESSAGE":
                    if (messageLangKey.pushMessage == vm.messageTemp) return;
                    messageLangKey.isError.pushMessage = true;
                    sendMessage(messageLangKey);
                    return;
                case "BOARD_TITLE":
                    if (messageLangKey.boardTitle == vm.messageTemp) return;
                    messageLangKey.isError.boardTitle = true;
                    sendMessage(messageLangKey);
                    return;
                case "BOARD_FIRST_MESSAGE":
                    if (messageLangKey.boardFirstMessage == vm.messageTemp)
                        return;
                    messageLangKey.isError.boardFirstMessage = true;
                    sendMessage(messageLangKey);
                    return;
                case "BOARD_SECOND_MESSAGE":
                    if (messageLangKey.boardSecondMessage == vm.messageTemp)
                        return;
                    messageLangKey.isError.boardSecondMessage = true;
                    sendMessage(messageLangKey);
                    return;
                case "BOARD_URL":
                    if (messageLangKey.boardUrl == vm.messageTemp) return;
                    messageLangKey.isError.boardUrl = true;
                    sendMessage(messageLangKey);
                    return;
            }
        }

        function sendMessage(messageLangKey) {
            clearErrorMessages();
            vm.info = "Sender...";
            return $http
                .patch(
                    URL_BIL_I_OSLO_SERVICE +
                        "api/Admin/SimpleNotificationService/Diesel/DefaultMessage",
                    {
                        id: messageLangKey.id,
                        pushMessage: messageLangKey.pushMessage,
                        boardTitle: messageLangKey.boardTitle,
                        boardFirstMessage: messageLangKey.boardFirstMessage,
                        boardSecondMessage: messageLangKey.boardSecondMessage,
                        boardUrl: messageLangKey.boardUrl,
                    }
                )
                .success(function (response) {
                    console.log("successfully edit message");
                    clearErrorMessages();
                    vm.success = "Teksten er oppdatert";
                    return response;
                })
                .error(function (e, status) {
                    clearErrorMessages();
                    if (status == 403) {
                        vm.error = "Mangler rettigheter...";
                    } else {
                        vm.error = "Oppdatering feilet!";
                    }
                    console.log("Message update failed");
                });
        }

        function isEditMode() {
            var isEditMode = false;

            angular.forEach(vm.dieselMessages, function (message) {
                if (message.messageNo.isEditable == null)
                    message.messageNo.isEditable = {};
                if (message.messageEn.isEditable == null)
                    message.messageEn.isEditable = {};

                if (
                    !angular.equals(message.messageNo.isEditable, {}) ||
                    !angular.equals(message.messageEn.isEditable, {})
                )
                    isEditMode = true;
            });
            return isEditMode;
        }

        function turnOffEditMode() {
            angular.forEach(vm.dieselMessages, function (message) {
                message.messageNo.isEditable = {};
                message.messageEn.isEditable = {};
            });
        }
        function turnOffErrorMessages() {
            angular.forEach(vm.dieselMessages, function (message) {
                message.messageNo.isError = {};
                message.messageEn.isError = {};
            });
        }

        function clearErrorMessages() {
            vm.info = null;
            vm.success = null;
            vm.error = null;
        }
    }
})();
