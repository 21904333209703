(function () {
    'use strict';

    angular
        .module('papp')
        .factory('Price', Price);

    Price.$inject = ['$resource', 'DateUtils', 'URL_BIL_I_OSLO_SERVICE'];

    function Price($resource, DateUtils, URL_BIL_I_OSLO_SERVICE) {
        var service = {};

        service.parking = $resource(URL_BIL_I_OSLO_SERVICE + 'api/Admin/ParkingPriceGroup/:id', {}, {
            'getPrices': {
                method: 'GET', isArray: false
            }
        });

        service.studdedTire = $resource(URL_BIL_I_OSLO_SERVICE + 'api/Admin/StuddedTirePrice', {}, {
            'query': {method: 'GET', isArray: true},
            'getStuddedTirePrices': {
                method: 'GET', isArray: true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    angular.forEach(data, function (value) {
                        value.buyFrom = DateUtils.convertLocalDateFromServer(value.buyFrom);
                        value.buyTo = DateUtils.convertLocalDateFromServer(value.buyTo);
                    });
                    return data;
                }
            },
            'save': {method: 'POST'},
            'update': {method: 'PUT'},
            'delete': {method: 'DELETE'}
        });

        service.season = $resource(URL_BIL_I_OSLO_SERVICE + 'api/Admin/StuddedTirePrice/Season', {}, {
            'query': {method: 'GET', isArray: true},
            'getSeason': {
                method: 'GET', isArray: false,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': {method: 'POST'},
            'update': {method: 'PUT'},
            'delete': {method: 'DELETE'}
        });

        function post(data) {
            return $http.post(URL_BIL_I_OSLO_SERVICE + 'api/Admin/ParkingPriceGroup', data, {}).success(function (response) {
                console.log('success from prices post');
                return response;
            });
        }

        return service;
    }
})();
