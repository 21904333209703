(function() {
    'use strict';

    angular
        .module('papp')
        .factory('Transaction', Transaction);

    Transaction.$inject = ['$resource', 'URL_BIL_I_OSLO_SERVICE'];

    function Transaction ($resource, URL_BIL_I_OSLO_SERVICE) {
        return $resource(URL_BIL_I_OSLO_SERVICE + 'api/Admin/Transaction', {}, {
            'query': {method: 'GET', isArray: false}
        });
    }
})();
