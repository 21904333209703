(function() {
    'use strict';

    angular
        .module('papp')
        .factory('TimeUtils', TimeUtils);

    TimeUtils.$inject = ['$filter'];

    function TimeUtils ($filter) {

        var service = {
            isTimeFormatTooHigh : isTimeFormatTooHigh,
            isTimeFormatTooLow : isTimeFormatTooLow,
        };

        return service;

        function isTimeFormatTooHigh(hour, min) {
            if (hour > 23 || isNotDigit(hour)) {
                return "Gyldige verdier for timer er: 00-23";
            }
            if (min > 59 || isNotDigit(min)) {
                return "Gyldige verdier for minutter er: 00-59";
            }
            if (hour != null && hour.length > 2) {
                return "Timer skrives med to tall.";
            }
            if (min != null && min.length > 2) {
                return "Minutter skrives med to tall.";
            }
            return null;
        }

        function isTimeFormatTooLow(hour, min) {
            if (hour < 0 || isNotDigit(hour)) {
                return "Gyldige verdier for timer er: 00-23";
            }
            if (min < 0 || isNotDigit(min)) {
                return "Gyldige verdier for minutter er: 00-59";
            }
        }

        function isNotDigit(number){
            return (number != null && number != "") && ! /^\d+$/.test(number)
        }
    }
})();
