(function () {
    'use strict';

    angular
        .module('papp')
        .controller('RefundController', RefundController);

    RefundController.$inject = ['PspJob', '$state', '$stateParams', 'DateUtils'];

    function RefundController(PspJob, $state, $stateParams, DateUtils) {
        var vm = this;
        var page = 1;
        var status = null;
        var date = null;
        
        vm.numberOfPages = 1;

        vm.jobs = [];

        vm.prevPage = prevPage;
        vm.nextPage = nextPage;
        vm.firstPage = firstPage;
        vm.lastPage = lastPage;
        vm.search = search;
        vm.clearSearch = clearSearch;
        vm.showRefund = showRefund;
        vm.openDateDatePicker = openDateDatePicker;

        vm.statuses = [
            {
                value: 'failed', label: 'Feilet'
            },
            {
                value: 'failing', label: 'Feilende'
            },
            {
                value: 'pending', label: 'Venter'
            },
            {
                value: 'attempt_in_progress', label: 'Forsøkes'
            },
            {
                value: 'processed', label: 'OK'
            }
        ];

        function prevPage() {
            toState(page - 1);
        }

        function nextPage() {
            toState(page + 1);
        }

        function firstPage() {
            toState(0);
        }

        function lastPage() {
            toState(vm.numberOfPages);
        }

        function toState(toPage){
            $state.go('refund', {
                page: toPage,
                status: (status ? status.value : null),
                date: (date ? DateUtils.convertLocalDateToServer(date) : null)
            });
        } 

        function search() {
            $state.go('refund', {
                page: 1,
                status: (vm.statusInput ? vm.statusInput.value : null),
                date: (vm.dateInput ? DateUtils.convertLocalDateToServer(vm.dateInput) : null)
            });
        }

        function clearSearch() {
            vm.statusInput = null;
            vm.dateInput = null;
            vm.toDateInput = null;

            $state.go('refund', {
                page: 1,
                status: null,
                date: null
            });
        }

        vm.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };

        vm.datePopupDatePicker = {
            opened: false
        };

        function openDateDatePicker() {
            vm.datePopupDatePicker.opened = true;
        }

        function showRefund(refund) {
            $state.go('refundDetails', {
                id: refund.id
            });
        }

        page = parseInt($stateParams.page, 10);
        if (!page) {
            page = 1;
        }

        if ($stateParams.status) {
            angular.forEach(vm.statuses, function (value, key) {
                if (value.value == $stateParams.status) {
                    status = value;
                }
            });
        }
        if ($stateParams.date)
            date = new Date($stateParams.date);

        vm.dateInput = date;
        vm.statusInput = status;

        PspJob.query({
            page: page,
            type: 'REFUND',
            status: (status ? status.value : null),
            date: (date ? DateUtils.convertLocalDateToServer(date) : null)
        }, function (result) {
            vm.jobs = result.pspJobs;
            
            vm.pagination = {
                page: page,
                total: result.total,
                itemsPerPage: result.itemsPerPage
            };

            vm.numberOfPages = result.itemsPerPage != 0 ? Math.ceil(result.total / result.itemsPerPage) : 0;
        });
    }
})(window.angular);
