(function () {
    "use strict";

    angular
        .module("papp")
        .controller("CustomerDetailsController", CustomerDetailsController);

    CustomerDetailsController.$inject = [
        "Customer",
        "Parking",
        "StuddedTire",
        "$state",
        "$stateParams",
        "DateUtils",
        "$scope",
        "Logs",
        "Principal",
    ];

    function CustomerDetailsController(
        Customer,
        Parking,
        StuddedTire,
        $state,
        $stateParams,
        DateUtils,
        $scope,
        Logs,
        Principal
    ) {
        var vm = this;
        vm.dateStart = null;
        vm.customerDetail = {};
        vm.logs = [];
        vm.load = load;

        vm.fromLogHour = "00";
        vm.fromLogMinute = "00";
        vm.toLogHour = "00";
        vm.toLogMinute = "00";

        vm.accessRequest = accessRequest;
        vm.anonomizeRequest = anonomizeRequest;

        var studdedTirePage = 1;
        vm.isFirstStuddedTirePage = true;
        vm.isLastStuddedTirePage = true;
        vm.prevStuddedTirePage = prevStuddedTirePage;
        vm.nextStuddedTirePage = nextStuddedTirePage;
        vm.showStuddedTire = showStuddedTire;

        var parkingPage = 1;
        vm.logUnmodified = false;
        vm.isFirstParkingPage = true;
        vm.isLastParkingPage = true;
        vm.prevParkingPage = prevParkingPage;
        vm.nextParkingPage = nextParkingPage;
        vm.searchParking = searchParking;
        vm.clearParkingSearch = clearParkingSearch;
        vm.showParking = showParking;
        vm.searchLogs = searchLogs;

        vm.openDatePicker = openDatePicker;
        vm.openLogFromDatePicker = openLogFromDatePicker;
        vm.openLogToDatePicker = openLogToDatePicker;

        vm.deleteCustomer = deleteCustomer;

        Principal.hasAuthority("bilioslo-admin").then(function (result) {
            vm.showLogSection = result;
        });

        vm.hours = [
            "00",
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
        ];
        vm.minutes = ["00", "15", "30", "45"];

        vm.load($stateParams.id);

        function accessRequest() {
            queryAccessRequest(vm.customerDetail.customer.id);
        }

        function anonomizeRequest() {
            updateToAnonomizedRequest(vm.customerDetail.customer.id);
        }

        function prevStuddedTirePage() {
            studdedTirePage = studdedTirePage - 1;
            queryStuddedTires();
        }

        function nextStuddedTirePage() {
            studdedTirePage = studdedTirePage + 1;
            queryStuddedTires();
        }

        function showStuddedTire(studdedTire) {
            $state.go("studdedTireDetails", {
                id: studdedTire.id,
            });
        }

        function prevParkingPage() {
            parkingPage = parkingPage - 1;
            queryParkings();
        }

        function nextParkingPage() {
            parkingPage = parkingPage + 1;
            queryParkings();
        }

        function searchParking() {
            parkingPage = 1;
            queryParkings();
        }

        function clearParkingSearch() {
            parkingPage = 1;
            vm.parkingPlateNumber = null;
            vm.dateStart = null;
            queryParkings();
        }

        function showParking(parking) {
            $state.go("parkingDetails", {
                id: parking.id,
            });
        }

        function searchLogs() {
            var fromDate = new Date(vm.logStartDate);
            var toDate = new Date(vm.logEndDate);
            fromDate.setHours(vm.fromLogHour);
            fromDate.setMinutes(vm.fromLogMinute);
            toDate.setHours(vm.toLogHour);
            toDate.setMinutes(vm.toLogMinute);

            console.log(
                "Sjekk logger fra " +
                    fromDate.toISOString() +
                    " til " +
                    toDate.toISOString()
            );

            Logs.getLogs(
                {
                    CustomerId: vm.customerDetail.customer.id,
                    FromDate: fromDate.toISOString(),
                    ToDate: toDate.toISOString(),
                    UnModified: vm.logUnmodified,
                },
                function (result) {
                    var loglines = [];

                    for (var i = 0; i < result.length; i++) {
                        var sp = result[i].split(" | ");
                        var logline = {
                            time: sp[0],
                            message: sp[1],
                        };
                        loglines.push(logline);
                    }
                    vm.logs = loglines;
                }
            );
        }

        vm.dateOptions = {
            formatYear: "yy",
            startingDay: 1,
        };

        vm.popupDatePicker = {
            opened: false,
        };

        vm.popupLogFromDatePicker = {
            opened: false,
        };
        vm.popupLogToDatePicker = {
            opened: false,
        };

        function openDatePicker() {
            vm.popupDatePicker.opened = true;
        }

        function openLogFromDatePicker() {
            vm.popupLogFromDatePicker.opened = true;
        }
        function openLogToDatePicker() {
            vm.popupLogToDatePicker.opened = true;
        }

        function queryAccessRequest(id) {
            Customer.getByIdAccessRequest.get({ id: id }, function (result) {
                var dataStr =
                    "data:text/json;charset=utf-8," +
                    encodeURIComponent(JSON.stringify(result, null, 4));
                var downloadAnchorNode = document.createElement("a");
                downloadAnchorNode.setAttribute("href", dataStr);
                downloadAnchorNode.setAttribute(
                    "download",
                    "customer" + id + ".json"
                );
                document.body.appendChild(downloadAnchorNode); // required for firefox
                downloadAnchorNode.click();
                downloadAnchorNode.remove();
            });
        }

        function updateToAnonomizedRequest(id) {
            var customerId = prompt(
                "Dette er et sikkerhetssteg for anonymisering av bruker. Når bruker anonymiseres vil ikke han eller hun kunne bruke appen mer. Tast inn bruker ID-en til bruker du prøver å anonymisere"
            );
            if (customerId === null) {
                return;
            } else if (customerId == id) {
                Customer.updateToAnonomizedRequest.update(
                    { id: customerId },
                    function (result) {
                        alert(
                            "Bruker med ID " +
                                id +
                                " er anonymisert. Siden lastes på nytt."
                        );
                        location.reload();
                    }
                );
            } else {
                alert(
                    "Du tastet inn " +
                        customerId +
                        ". Det samsvarer ikke med bruker-ID-en til brukeren du prøver å anonymisere."
                );
            }
        }

        function queryStuddedTires() {
            StuddedTire.query(
                {
                    page: studdedTirePage,
                    customerId: $stateParams.id,
                },
                function (result) {
                    vm.studdedTires = result.studdedTires;
                    vm.isFirstStuddedTirePage = studdedTirePage <= 1;
                    vm.isLastStuddedTirePage =
                        result.page * result.itemsPerPage >= result.total;
                }
            );
        }

        function queryParkings() {
            Parking.query(
                {
                    page: parkingPage,
                    plateNumber: vm.parkingPlateNumber,
                    customerId: $stateParams.id,
                    dateStart: vm.dateStart
                        ? DateUtils.convertLocalDateToServer(vm.dateStart)
                        : null,
                },
                function (result) {
                    vm.parkings = result.parkings;
                    vm.isFirstParkingPage = parkingPage <= 1;
                    vm.isLastParkingPage =
                        result.page * result.itemsPerPage >= result.total;
                }
            );
        }

        function load(id) {
            Customer.getById.get({ id: id }, function (result) {
                vm.customerDetail = result;
            });

            $scope.logFromHour = "00";
            $scope.logFromMinute = "00";

            $scope.logToHour = "00";
            $scope.logToMinute = "00";

            queryStuddedTires();
            queryParkings();
        }

        function deleteCustomer() {
            $state.go("customerDelete", {
                id: vm.customerDetail.customer.id,
            });
        }
    }
})();
