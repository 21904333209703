(function() {
    'use strict';

    angular
        .module('papp')
        .filter('cardExpiryDate', cardExpiryDate);

    function cardExpiryDate() {
        return cardExpiryDateFilter;

        function cardExpiryDateFilter (input) {
            input = input.toString()
            return input.substring(2,4) + "/" + input.substring(0,2)
        }
    }
})();
