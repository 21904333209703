(function () {
    "use strict";

    angular.module("papp").config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider.state("parking", {
            parent: "app",
            url: "/parking?page&plateNumber&email&dateStart&isElectric",
            data: {
                authorities: ["bilioslo-admin", "bilioslo-admin-lese"],
            },
            views: {
                "content@": {
                    templateUrl: "app/parking/parking.html",
                    controller: "ParkingController",
                    controllerAs: "vm",
                },
            },
        });
    }
})();
