(function () {
    "use strict";

    angular
        .module("papp")
        .controller("AppversionController", AppversionController);

    AppversionController.$inject = [
        "Appversion",
        "DateUtils",
        "$http",
        "URL_BIL_I_OSLO_SERVICE",
    ];

    function AppversionController(
        Appversion,
        DateUtils,
        $http,
        URL_BIL_I_OSLO_SERVICE
    ) {
        var vm = this;

        vm.add = {};
        vm.add.isValid = true;
        vm.add.notifyUser = false;

        vm.openDatePicker = openDatePicker;
        vm.setValid = setValid;
        vm.setEditable = setEditable;
        vm.turnOffEditMode = turnOffEditMode;
        vm.update = update;

        vm.popupDatePicker = false;
        vm.isEditable = true;

        function setValid(parameter) {
            if (parameter == null) {
                parameter = true;
                return;
            }
            parameter = !parameter;
        }

        function setNotify(parameter) {
            if (parameter == null) {
                parameter = false;
                return;
            }
            parameter = !parameter;
        }

        function setEditable(appversion) {
            turnOffEditMode();
            appversion.isEditable = true;
        }

        function openDatePicker(appversion) {
            if (appversion.isEditable) {
                appversion.popupDatePicker = true;
                return;
            }
            appversion.popupDatePicker = false;
        }
        get();

        function get() {
            Appversion.get().$promise.then(function (appversions) {
                vm.appversions = appversions;
            });
        }

        vm.platforms = [
            {
                id: "IOS",
                name: "iOS",
            },
            {
                id: "ANDROID",
                name: "Android",
            },
        ];

        function validateInsert() {
            vm.success = null;
            vm.error = null;
            if (vm.add == null) {
                vm.error = "Felter for ny appversjon må være fylt ut";
                return;
            }
            if (vm.add.versionNumber == null || vm.add.versionNumber == "") {
                vm.error = "Versjon for ny appversjon må være fylt ut";
                return;
            }
            if (vm.add.platform == null || vm.add.platform == "") {
                vm.error = "Platform for ny appversjon må være fylt ut";
                return;
            }
            if (vm.add.releaseDate == null || vm.add.releaseDate == "") {
                vm.error = "Release dato for ny appversjon må være fylt ut";
                return;
            }
            if (vm.add.releaseDate > Date.now()) {
                vm.error = "Release dato for ny appversjon må være før i dag";
                return;
            }
            if (vm.add.isValid == null) {
                vm.error = "Gyldighet for ny appversjon må være fylt ut";
                return;
            }
            if (vm.add.notifyUser == null) {
                vm.error = "Varsling for ny appversjon må være fylt ut";
                return;
            }
        }

        function validateUpdate(appversion) {
            vm.success = null;
            vm.error = null;
            if (appversion == null) {
                vm.error = "Felter for appversjon må være fylt ut";
                return;
            }
            if (
                appversion.versionNumber == null ||
                appversion.versionNumber == ""
            ) {
                vm.error = "Versjon for appversjon må være fylt ut";
                return;
            }
            if (appversion.platform == null || appversion.platform == "") {
                vm.error = "Platform for appversjon må være fylt ut";
                return;
            }
            if (
                appversion.releaseDate == null ||
                appversion.releaseDate == ""
            ) {
                vm.error = "Release dato for appversjon må være fylt ut";
                return;
            }
            if (appversion.releaseDate > Date.now()) {
                vm.error = "Release dato for appversjon må være før i dag";
                return;
            }
            if (appversion.notifyUser == null) {
                vm.error = "Vasling for appversjon må være fylt ut";
                return;
            }
            if (appversion.isValid == null) {
                vm.error = "Gyldighet for appversjon må være fylt ut";
                return;
            }
            if (appversion.isValid == false && appversion.isNewest) {
                vm.error = "Nyeste appversjon kan ikke bli satt som ugyldig";
                return;
            }
            if (
                appversion.isValid == false &&
                (appversion.validChangeDescription == null ||
                    appversion.validChangeDescription == "")
            ) {
                vm.error = "Venligst angi hvorfor denne er satt som ugyldig";
                return;
            }
        }

        function turnOffEditMode() {
            angular.forEach(vm.appversions, function (appversion) {
                appversion.isEditable = false;
            });
        }

        vm.insert = function (event) {
            validateInsert();
            if (vm.error != null) return;
            console.log("Inserting appversion...");

            var json = angular.copy(vm.add);
            json.releaseDate = DateUtils.convertLocalDateToServer(
                vm.add.releaseDate
            );

            $http
                .post(URL_BIL_I_OSLO_SERVICE + "api/Admin/AppVersion", json, {})
                .success(function (response) {
                    console.log("Successfully added appversion");
                    vm.success = "Appversjonen er lagt til";
                    get();
                    return response;
                })
                .error(function (e, status) {
                    get();
                    if (status == 422)
                        vm.error =
                            "Dette versjonsnummeret eksisterer allerede for denne platformen";
                    else vm.error = "Feilet!";
                    console.log("Inserting appversion failed");
                });
        };

        function update(appversion) {
            validateUpdate(appversion);
            if (vm.error != null) return;

            console.log("Updating appversion...");

            var json = angular.copy(appversion);
            json.releaseDate = DateUtils.convertLocalDateToServer(
                appversion.releaseDate
            );

            $http
                .put(
                    URL_BIL_I_OSLO_SERVICE +
                        "api/Admin/AppVersion/" +
                        appversion.id,
                    json,
                    {}
                )
                .success(function (response) {
                    console.log("Successfully updated appversion");
                    vm.success = "Oppdatering vellykket!";
                    get();
                    return response;
                })
                .error(function (e, status) {
                    vm.error = "Oppdatering feilet!";
                    get();
                    if (status == 422)
                        vm.error =
                            "Versjonsnummer på denne platformen eksisterer allerede";
                    else vm.error = "Oppdatering feilet!";
                    console.log("Update of appversion failed");
                });
        }
    }
})();
