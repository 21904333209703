(function () {
    "use strict";

    angular.module("papp").factory("Parking", Parking);

    Parking.$inject = ["$resource", "URL_BIL_I_OSLO_SERVICE"];

    function Parking($resource, URL_BIL_I_OSLO_SERVICE) {
        return $resource(
            URL_BIL_I_OSLO_SERVICE + "api/Admin/Parking/:id",
            {},
            {
                query: { method: "GET", isArray: false },
                get: {
                    method: "GET",
                    transformResponse: function (data) {
                        data = angular.fromJson(data);
                        return data;
                    },
                },
            }
        );
    }
})();
