(function () {
    "use strict";

    angular
        .module("papp")
        .controller("ParkingDetailsController", ParkingDetailsController);

    ParkingDetailsController.$inject = [
        "Parking",
        "Logs",
        "DateUtils",
        "$stateParams",
        "$scope",
        "Principal",
    ];

    function ParkingDetailsController(
        Parking,
        Logs,
        DateUtils,
        $stateParams,
        $scope,
        Principal
    ) {
        var vm = this;

        vm.load = load;
        vm.parkingDetail = {};

        vm.logs = [];
        vm.showLogButton = true;
        vm.searchLog = loadLogs;

        vm.preTimes = [];
        vm.postTimes = [];

        vm.load($stateParams.id);

        Principal.hasAuthority("bilioslo-admin").then(function (result) {
            vm.showLogSection = result;
        });

        function load(id) {
            Parking.get({ id: id }, function (result) {
                vm.parkingDetail = result;
                fillPreAndPostTimes();
                $scope.logFromDate = vm.preTimes[0].date;
                $scope.logToDate = vm.postTimes[0].date;
            });
        }

        function fillPreAndPostTimes() {
            // fill preTimes
            var startTime = vm.parkingDetail.parking.startTime;
            var intervallMinutes = [15, 60, 120, 180, 240, 300, 480, 1440];
            for (var i = 0; i < intervallMinutes.length; i++) {
                var intervall = intervallMinutes[i];
                var preTime = {
                    date: new Date(
                        DateUtils.convertLocalDateTimeFromServer(
                            startTime
                        ).getTime() -
                            intervall * 60 * 1000
                    ).toISOString(),
                    text:
                        intervall < 60
                            ? "-" + intervall + " min"
                            : "-" + intervall / 60 + " timer",
                };
                var postTime = {
                    date: new Date(
                        DateUtils.convertLocalDateTimeFromServer(
                            startTime
                        ).getTime() +
                            intervall * 60 * 1000
                    ).toISOString(),
                    text:
                        intervall < 60
                            ? "+" + intervall + " min"
                            : "+" + intervall / 60 + " timer",
                };
                vm.preTimes.push(preTime);
                vm.postTimes.push(postTime);
            }
        }

        function loadLogs(unModified) {
            vm.showLogButton = false;
            vm.logs = [{ time: "", message: "Laster...." }];

            console.log(
                "Henter logger for kunde: " +
                    vm.parkingDetail.customer.id +
                    " fra: " +
                    $scope.logFromDate +
                    " til: " +
                    $scope.logToDate
            );
            Logs.getLogs(
                {
                    CustomerId: vm.parkingDetail.customer.id,
                    FromDate: $scope.logFromDate,
                    ToDate: $scope.logToDate,
                    UnModified: unModified,
                },
                function (result) {
                    var loglines = [];

                    for (var i = 0; i < result.length; i++) {
                        var sp = result[i].split(" | ");
                        var logline = {
                            time: sp[0],
                            message: sp[1],
                        };
                        loglines.push(logline);
                    }
                    vm.logs = loglines;
                }
            );
        }
    }
})();
