(function () {
    'use strict';

    angular
        .module('papp')
        .controller('InfoMessageController', InfoMessageController);

    InfoMessageController.$inject = ['$http', '$state', 'Message', 'DateUtils', 'TimeUtils', '$uibModal', '$sce', 'URL_BIL_I_OSLO_SERVICE'];

    function InfoMessageController($http, $state, Message, DateUtils, TimeUtils, $uibModal, $sce, URL_BIL_I_OSLO_SERVICE) {
        var vm = this;

        vm.loadAll = function () {
            getMessages();
            getNextId();
        };    

        vm.fromDate = null;
        vm.toDate = null;
        vm.messageLanguage = "nb";
        vm.messageTitle =null;
        vm.messageText=null;
        vm.messageUrl=null;
        vm.errorMessage = null;

        vm.popupDatePickerFrom = {
            opened: false
        };
        vm.popupDatePickerTo = {
            opened: false
        };
        vm.page = 1
        vm.isOnlyOnePage = 1;

        vm.openDatePickerFrom = openDatePickerFrom;
        vm.openDatePickerTo = openDatePickerTo;
        vm.delete = deleteMessage;
        vm.submit = submit;
        vm.prevPage = prevPage;
        vm.nextPage = nextPage;

        vm.loadAll();

        function getMessages() {
            Message.messages.getMessages({
                page: vm.page
            }, function (result) {
                vm.messages = result.informationMessages;
                vm.isFirstPage = vm.page <= 1;
                vm.isLastPage =  (result.page * result.itemsPerPage >= result.total) 
                vm.isOnlyOnePage = vm.isFirstPage && vm.isLastPage;
            });
        }

        
        function openDatePickerFrom() {
            vm.popupDatePickerFrom.opened = true;
        }

        function openDatePickerTo() {
            vm.popupDatePickerTo.opened = true;
        }

        function submit() {
            if (!validate())
                return false;
            checkIdLanguageInDb(function(id){
                if (id==null) {
                    $http.post(URL_BIL_I_OSLO_SERVICE + 'api/Admin/InformationMessage',{                        
                        messageId: vm.messageId,
                        language: vm.messageLanguage,
                        title: vm.messageTitle,
                        text: vm.messageText,
                        url: vm.messageUrl,
                        validFrom: vm.fromDate,
                        validUntil: vm.toDate 
                    }
                        ).success(function (response) {
                            console.log('successfully added new message to DB');                
                            getNextId();
                            getMessages();
                        }).error(function (e, status) {                
                            if (status == 403) {
                                vm.errorMessage = $sce.trustAsHtml("Mangler rettigheter...");
                            } else {
                                vm.errorMessage = $sce.trustAsHtml("Klarte ikke legge inn meldingen.");
                            }
                            console.log('Error while fetching next available id from database');
                        });
                } else {
                    $http.put(URL_BIL_I_OSLO_SERVICE + 'api/Admin/InformationMessage/'+vm.messageId,{
                        id: id,
                        messageId: vm.messageId,
                        language: vm.messageLanguage,
                        title: vm.messageTitle,
                        text: vm.messageText,
                        url: vm.messageUrl,
                        validFrom: vm.fromDate,
                        validUntil: vm.toDate 
                    }
                        ).success(function (response) {
                            console.log('successfully updated message');                
                            getNextId();
                            getMessages();
                        }).error(function (e, status) {                
                            if (status == 403) {
                                vm.errorMessage = $sce.trustAsHtml("Mangler rettigheter...");
                            } else {
                                vm.errorMessage = $sce.trustAsHtml("Klarte ikke oppdatere meldingen.");
                            }
                            console.log('Error while updating message in database');
                        });
                    getMessages();
                }            
            });        
            return true;
        }
        
        function validate() {
            var errors = [];
            if (isNaN(vm.messageId)) errors.push("Id-feltet har ikke gyldig verdi.");
            if (vm.messageLanguage!="en" && vm.messageLanguage!="nb") errors.push("Språk har ikke gyldig verdi. ");
            if (vm.fromDate == null || typeof vm.fromDate.getMonth !== 'function') errors.push("Fra-dato må spesifiseres med formatet dd.mm.åååå. ");
            if (vm.toDate == null || typeof vm.toDate.getMonth !== 'function') errors.push("Til-dato må spesifiseres med formatet dd.mm.åååå. ");
            if (vm.messageTitle==null || vm.messageTitle.length<1) errors.push("Melding må inneholde en tittel. ");
            if (vm.messageText==null || vm.messageText.length<1) errors.push("Melding må inneholde en tekst. ");

            vm.errorMessage =  $sce.trustAsHtml(errors.join("<br/>"));

            return vm.errorMessage=="";
        }

        function checkIdLanguageInDb(success) {
            $http.get(URL_BIL_I_OSLO_SERVICE + 'api/Admin/InformationMessage/'+vm.messageId+'/'+vm.messageLanguage
            ).success(function (response) {
                console.log('successfully fetched messages');                
                if (response.length>0) {
                    if (response.length>1)
                    {
                        vm.errorMessage = $sce.trustAsHtml("Databasen inneholder duplikate forekomster, vennligst kontakt A-Team");
                    }
                    if (confirm("Kombinasjonen eksisterer allerede, ønsker du å overskrive eksisterende?")) {           
                        success(response[0].id);
                    }
                } else { 
                    success();
                }                
            }).error(function (e, status) {                
                if (status == 403) {
                    vm.errorMessage = $sce.trustAsHtml("Mangler rettigheter...");
                } else {
                    vm.errorMessage = $sce.trustAsHtml("Klarte ikke sjekke om kombinasjon id/spåk allerede er i bruk.");
                }
                console.log('Error while fetching message by id and language from database');
            });
        }

        function getNextId() {
            return $http.get(URL_BIL_I_OSLO_SERVICE + 'api/Admin/InformationMessage/NextId')
            .success(function (response) {
                console.log('successfully fetched next available id');                
                vm.messageId = response;
            })
            .error(function (e, status) {                
                if (status == 403) {
                    vm.errorMessage = "Mangler rettigheter...";
                } else {
                    vm.errorMessage = "Klarte ikke hente neste tilgjengelige ID.";
                }
                console.log('Error while fetching next available id from database');
            });
        }
         
        function prevPage() {
            vm.page--;
            getMessages();
        }

        function nextPage() {
            vm.page++;
            getMessages();
        }

        function deleteMessage(id) {
            if (confirm("Er du sikker på at du ønsker å slette denne raden?")) {
                $http.delete(URL_BIL_I_OSLO_SERVICE + 'api/Admin/InformationMessage/'+id
                    ).success(function (response) {
                        console.log('successfully deleted message');                
                        getNextId();
                        getMessages();
                    }).error(function (e, status) {                
                        if (status == 403) {
                            vm.errorMessage = $sce.trustAsHtml("Mangler rettigheter...");
                        } else {
                            vm.errorMessage = $sce.trustAsHtml("Klarte ikke slette meldingen.");
                        }
                        console.log('Error while deleting message in database');
                    });
            }            
        }

    }
})();
