(function () {
    "use strict";

    angular.module("papp").config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider.state("notificationHistoryDetails", {
            parent: "app",
            url: "/notification/history/details/:id",
            data: {
                authorities: [
                    "bilioslo-admin-dieselvarsel",
                    "bilioslo-admin-veivarsel",
                ],
            },
            views: {
                "content@": {
                    templateUrl:
                        "app/notification/history-details/notification.history.details.html",
                    controller: "NotificationHistoryDetailsController",
                    controllerAs: "vm",
                },
            },
        });
    }
})();
