(function () {
    'use strict';

    angular
        .module('papp')
        .controller('StuddedTireDetailsController', StuddedTireDetailsController);

    StuddedTireDetailsController.$inject = ['StuddedTire', '$stateParams'];

    function StuddedTireDetailsController(StuddedTire, $stateParams) {
        var vm = this;

        vm.load = load;
        vm.studdedTireDetail = {};

        vm.load($stateParams.id);

        function load (id) {
            StuddedTire.get({id: id}, function(result) {
                vm.studdedTireDetail = result;
            });
        }
    }
})();
