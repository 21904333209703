(function () {
    "use strict";

    angular.module("papp").config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider.state("studdedTireDetails", {
            parent: "app",
            url: "/studded-tire/details/:id",
            data: {
                authorities: ["bilioslo-admin", "bilioslo-admin-lese"],
            },
            views: {
                "content@": {
                    templateUrl:
                        "app/studded-tire/details/studded-tire.details.html",
                    controller: "StuddedTireDetailsController",
                    controllerAs: "vm",
                },
            },
        });
    }
})();
