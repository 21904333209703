(function () {
    "use strict";

    angular.module("papp").factory("authInterceptor", authInterceptor);

    authInterceptor.$inject = [
        "$rootScope",
        "$q",
        "$location",
        "$localStorage",
        "$sessionStorage",
    ];

    function authInterceptor(
        $rootScope,
        $q,
        $location,
        $localStorage,
        $sessionStorage
    ) {
        var service = {
            request: request,
        };

        return service;

        function request(config) {
            /*jshint camelcase: false */
            config.headers = config.headers || {};
            var identity = $localStorage.identity || $sessionStorage.identity;

            if (identity && config.url.indexOf("/api/") > -1) {
                config.headers.Authorization =
                    "Bearer " + identity.access_token;
            }

            return config;
        }
    }
})();
