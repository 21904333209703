(function() {
    'use strict';

    angular
        .module('papp')
        .factory('StuddedTire', StuddedTire);

    StuddedTire.$inject = ['$resource', 'URL_BIL_I_OSLO_SERVICE'];

    function StuddedTire ($resource, URL_BIL_I_OSLO_SERVICE) {
        return $resource(URL_BIL_I_OSLO_SERVICE + 'api/Admin/StuddedTire/:id', {}, {
            'query': {method: 'GET', isArray: false},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });
    }
})();
