(function () {
    'use strict';

    angular
        .module('papp')
        .controller('StuddedTirePriceController', StuddedTirePriceController);

    StuddedTirePriceController.$inject = ['Price'];

    function StuddedTirePriceController(Price) {
        var vm = this;

        vm.error = null;
        vm.success = null;

        vm.studdedTirePrices = Price.studdedTire.getStuddedTirePrices();
        vm.season = Price.season.getSeason();
    }
})();
