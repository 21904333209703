(function () {
    "use strict";

    angular.module("papp").controller("ParkingController", ParkingController);

    ParkingController.$inject = [
        "Parking",
        "$state",
        "$stateParams",
        "DateUtils",
    ];

    function ParkingController(Parking, $state, $stateParams, DateUtils) {
        var vm = this;
        var page = 1;
        var plateNumber = null;
        var dateStart = null;
        var email = null;
        var isElectricParking = null;
        var isNormalParking = null;

        vm.numberOfPages = 1;

        vm.parkings = [];

        vm.plateNumberInput = null;
        vm.emailInput = null;
        vm.dateStartInput = null;

        vm.prevPage = prevPage;
        vm.nextPage = nextPage;
        vm.firstPage = firstPage;
        vm.lastPage = lastPage;
        vm.search = search;
        vm.clearSearch = clearSearch;
        vm.showParking = showParking;
        vm.openDatePicker = openDatePicker;

        function prevPage() {
            toState(page - 1);
        }

        function nextPage() {
            toState(page + 1);
        }

        function firstPage() {
            toState(0);
        }

        function lastPage() {
            toState(vm.numberOfPages);
        }

        function toState(toPage) {
            $state.go("parking", {
                page: toPage,
                plateNumber: plateNumber,
                email: email,
                dateStart: dateStart
                    ? DateUtils.convertLocalDateToServer(dateStart)
                    : null,
                isElectricInput: vm.isElectricInput,
                isNormalInput: vm.isNormalInput,
            });
        }

        function search() {
            var isEl = isElectricSearch();
            if (isEl === null) {
                vm.isElectricInput = true;
                vm.isNormalInput = true;
            }

            $state.go("parking", {
                page: 1,
                plateNumber: vm.plateNumberInput
                    ? vm.plateNumberInput.toUpperCase()
                    : null,
                email: vm.emailInput ? vm.emailInput.toLowerCase() : null,
                dateStart: vm.dateStartInput
                    ? DateUtils.convertLocalDateToServer(vm.dateStartInput)
                    : null,
                isElectric: isElectricSearch(),
            });
        }

        function clearSearch() {
            vm.plateNumberInput = null;
            vm.emailInput = null;
            vm.dateStartInput = null;
            vm.isElectricInput = true;
            vm.isNormalInput = true;

            $state.go("parking", {
                page: 1,
                plateNumber: null,
                email: null,
                dateStart: null,
                isElectricInput: true,
                isNormalInput: true,
                isElectric: null,
            });
        }

        function showParking(parking) {
            $state.go("parkingDetails", {
                id: parking.id,
            });
        }

        page = parseInt($stateParams.page, 10);
        if (!page) {
            page = 1;
        }

        plateNumber = $stateParams.plateNumber;
        email = $stateParams.email;
        if ($stateParams.dateStart)
            dateStart = new Date($stateParams.dateStart);

        vm.plateNumberInput = plateNumber;
        vm.emailInput = email;
        vm.dateStartInput = dateStart;

        if ($stateParams.isElectric === undefined) {
            isNormalParking = true;
            isElectricParking = true;
        } else if ($stateParams.isElectric === "false") {
            isElectricParking = false;
            isNormalParking = true;
        } else {
            isElectricParking = true;
            isNormalParking = false;
        }

        vm.isNormalInput = isNormalParking;
        vm.isElectricInput = isElectricParking;

        vm.dateOptions = {
            formatYear: "yy",
            startingDay: 1,
        };

        vm.popupDatePicker = {
            opened: false,
        };

        function openDatePicker() {
            vm.popupDatePicker.opened = true;
        }

        function isElectricSearch() {
            if (
                (vm.isElectricInput && vm.isNormalInput) ||
                (!vm.isElectricInput && !vm.isNormalInput)
            ) {
                return null;
            } else if (vm.isElectricInput) {
                return true;
            } else {
                return false;
            }
        }

        Parking.query(
            {
                page: page,
                plateNumber: plateNumber,
                email: email,
                dateStart: dateStart
                    ? DateUtils.convertLocalDateToServer(dateStart)
                    : null,
                isElectric: isElectricSearch(),
            },
            function (result) {
                vm.parkings = result.parkings;
                plateNumber = result.plateNumber;
                email = result.email;

                vm.pagination = {
                    page: page,
                    total: result.total,
                    itemsPerPage: result.itemsPerPage,
                };

                vm.numberOfPages =
                    result.itemsPerPage != 0
                        ? Math.ceil(result.total / result.itemsPerPage)
                        : 0;
            }
        );
    }
})(window.angular);
