(function () {
    "use strict";

    angular.module("papp").factory("AuthServerProvider", AuthServerProvider);

    AuthServerProvider.$inject = [
        "$sessionStorage",
        "ONELOGIN_CLIENT_ID",
        "ONELOGIN_SUBDOMAIN",
        "ONELOGIN_RESOURCE",
        "ONELOGIN_REFRESH_WHEN_REMAINING",
    ];

    function AuthServerProvider(
        $sessionStorage,
        ONELOGIN_CLIENT_ID,
        ONELOGIN_SUBDOMAIN,
        ONELOGIN_RESOURCE
    ) {
        var service = {
            hasValidToken: hasValidToken,
            storeToken: storeToken,
            updateToken: updateToken,
            getToken: getToken,
            logout: logout,
            getUserManager: getUserManager,
            getAuthority: getAuthority,
            getClientId: getClientId,
        };
        var settings = {
            authority: "https://" + ONELOGIN_SUBDOMAIN + ".onelogin.com/oidc/2",
            client_id: ONELOGIN_CLIENT_ID,
            redirect_uri: window.location.origin,
            response_type: "code",
            scope: "openid profile groups",
            resource: ONELOGIN_RESOURCE,
            filterProtocolClaims: true,
            loadUserInfo: true,
        };
        var mgr = new Oidc.UserManager(settings);

        return service;

        function getUserManager() {
            return mgr;
        }
        function getAuthority() {
            return settings.authority;
        }
        function getClientId() {
            return settings.client_id;
        }

        function getToken() {
            console.debug("Henter token");
            return $sessionStorage.identity;
        }

        function hasValidToken() {
            console.debug("Sjekker om Token er gyldig");
            var token = this.getToken();
            return (
                token &&
                token.expires &&
                token.expires > Math.round(Date.now() / 1000)
            );
        }

        function storeToken(jwt) {
            console.debug("Lagrer token");
            $sessionStorage.identity = jwt;
        }

        function updateToken(tokenData) {
            console.debug("oppdaterer token");
            mgr.getUser().then(function (user) {
                if (user) {
                    user.access_token = tokenData.access_token;
                    user.refresh_token = tokenData.refresh_token;
                    user.expires_at =
                        Math.round(Date.now() / 1000) + tokenData.expires_in;
                    user.id_token = tokenData.id_token;
                    $sessionStorage.identity = user;
                    mgr.storeUser(user);
                    console.log("Updated tokens successfully");
                    return true;
                }
                console.error("Error invalid user");
                return false;
            });
        }

        function logout() {
            console.debug("logger ut");
            delete $sessionStorage.identity;
            delete $sessionStorage.authenticationToken;
            mgr.removeUser().then(function (a) {
                console.debug("Fjernet bruker i Oidc UserManager " + a);
            });
        }
    }
})();
