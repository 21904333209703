(function () {
    "use strict";

    angular.module("papp").filter("parkingMaksPTid", parkingMaksPTid);

    function parkingMaksPTid() {
        return parkingMaksPTidFilter;

        function parkingMaksPTidFilter(input) {
            switch (input) {
                case 30:
                    return "0,5 timer";
                case 60:
                    return "1 time";
                case 120:
                    return "2 timer";
                case 180:
                    return "3 timer";
                case 240:
                    return "4 timer";
                case 480:
                    return "8 timer";
                case 660:
                    return "11 timer";
                case 1440:
                    return "1 døgn";
                case 10080:
                    return "1 uke";
                case 40320:
                    return "4 uker";
                default:
                    return "Ubegrenset";
            }
        }
    }
})();
