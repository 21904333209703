(function () {
    "use strict";

    angular.module("papp").config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider.state("roadMaintenanceNotification", {
            parent: "Notification",
            url: "/notification/road-maintenance",
            data: {
                authorities: ["bilioslo-admin-veivarsel"],
            },
            views: {
                "content@": {
                    templateUrl:
                        "app/notification/road-maintenance/road-maintenance.html",
                    controller: "RoadMaintenanceNotificationController",
                    controllerAs: "vm",
                },
            },
        });
    }
})();
