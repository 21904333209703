// const { merge } = require("lodash");

(function () {
    "use strict";

    angular.module("papp").factory("Principal", Principal);

    Principal.$inject = ["$q", "AuthServerProvider"];

    function Principal($q, AuthServerProvider) {
        var _identity,
            _authenticated = false;

        var service = {
            authenticate: authenticate,
            hasAnyAuthority: hasAnyAuthority,
            hasAuthority: hasAuthority,
            identity: identity,
            isAuthenticated: isAuthenticated,
            isIdentityResolved: isIdentityResolved,
        };

        return service;

        function authenticate(identity) {
            console.debug("autentiserer");
            _identity = identity;
            _authenticated = identity !== null; //  && identity.expires_at > Date.now();
            AuthServerProvider.storeToken(identity);
            // $sessionStorage.identity = identity;
        }

        function hasAnyAuthority(authorities) {
            console.debug("kontrollerer mot flere tilganger");
            if (!_authenticated || !_identity || !_identity.profile.groups) {
                return false;
            }

            if (
                _identity.profile.groups.indexOf("bilioslo-admin-super") !== -1
            ) {
                return true;
            }

            for (var i = 0; i < authorities.length; i++) {
                if (_identity.profile.groups.indexOf(authorities[i]) !== -1) {
                    return true;
                }
            }

            return false;
        }

        function hasAuthority(authority) {
            console.debug("kontrollerer mot enkel tilgang");
            if (!_authenticated) {
                return $q.when(false);
            }

            return this.identity().then(
                function (_id) {
                    if (!_id.profile.groups) {
                        return false;
                    }

                    if (
                        _id.profile.groups.indexOf("bilioslo-admin-super") !==
                            -1 &&
                        authority.indexOf("varsel") == -1
                    ) {
                        return true;
                    }

                    return _id.profile.groups.indexOf(authority) !== -1;
                },
                function () {
                    return false;
                }
            );
        }
        function identity(force) {
            console.debug("henter identitet: ");
            var deferred = $q.defer();

            if (force === true) {
                _identity = undefined;
                //$sessionStorage.identity = undefined;
            }

            // check and see if we have retrieved the identity data from the server.
            // if we have, reuse it by immediately resolving
            if (angular.isDefined(_identity) && _identity != null) {
                deferred.resolve(_identity);
                //AuthServerProvider.storeToken(_identity);
                //                $sessionStorage.identity = _identity;
                return deferred.promise;
            }

            if (AuthServerProvider.getToken() != null) {
                getAccountThen(AuthServerProvider.getToken());
            } else {
                getAccountCatch();
            }

            // retrieve the identity data from the server, update the identity object, and then resolve.
            //Account.get().$promise.then(getAccountThen).catch(getAccountCatch);

            return deferred.promise;

            function getAccountThen(account) {
                console.debug("setter identitet");
                _identity = account;
                _authenticated = true;
                AuthServerProvider.storeToken(_identity);
                deferred.resolve(_identity);
            }

            function getAccountCatch() {
                console.debug("setter null identitet");
                _identity = null;
                _authenticated = false;
                deferred.resolve(_identity);
            }
        }

        function isAuthenticated() {
            return _authenticated;
        }

        function isIdentityResolved() {
            console.debug("har vi en identitet");
            return angular.isDefined(_identity);
        }
    }
})();
