(function () {
    "use strict";

    angular.module("papp").controller("HomeController", HomeController);

    HomeController.$inject = ["$state", "Principal"];

    function HomeController($state, Principal) {
        Principal.hasAuthority("bilioslo-admin-veivarsel").then(function (
            result
        ) {
            if (result) {
                $state.go("roadMaintenanceNotification");
            }
        });
        Principal.hasAuthority("bilioslo-admin-dieselvarsel").then(function (
            result
        ) {
            if (result) {
                $state.go("dieselSendNotification");
            }
        });
        Principal.hasAuthority("bilioslo-admin-lese").then(function (result) {
            if (result) {
                $state.go("parking");
            }
        });
        Principal.hasAuthority("bilioslo-admin").then(function (result) {
            if (result) {
                $state.go("parking");
            }
        });
    }
})();
