(function (angular) {
    'use strict';

    function PaginationController() {
        var ctrl = this;

        ctrl.prevPage = function () {
            ctrl.toPrevPage();
        }

        ctrl.nextPage = function () {
            ctrl.toNextPage();
        }

        ctrl.firstPage = function () {
            ctrl.toFirstPage();
        }

        ctrl.lastPage = function () {
            ctrl.toLastPage();
        }

        ctrl.isFirstPage = ctrl.pagination.page <= 1;

        ctrl.numberOfResults = ctrl.pagination.total;
        ctrl.pageEnd = ctrl.pagination.itemsPerPage * ctrl.pagination.page;
        ctrl.pageStart = ctrl.pageEnd - ctrl.pagination.itemsPerPage + 1;

        if ((ctrl.pagination.page * ctrl.pagination.itemsPerPage) < ctrl.pagination.total) {
            ctrl.isLastPage = false;
        } else {
            ctrl.isLastPage = true;
            ctrl.pageEnd = ctrl.pagination.total;
        }
    }

    angular.module('papp').component('paginationComponent', {
        templateUrl: 'app/pagination/pagination.html',
        controller: PaginationController,
        bindings: {
            pagination: "<",
            toPrevPage: "&",
            toNextPage: "&",
            toFirstPage: "&",
            toLastPage: "&"
        }
    });
})(window.angular);
