(function () {
    "use strict";

    angular.module("papp").config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider.state("parkingDetails", {
            parent: "app",
            url: "/parking/details/:id",
            data: {
                authorities: ["bilioslo-admin", "bilioslo-admin-lese"],
            },
            views: {
                "content@": {
                    templateUrl: "app/parking/details/parking.details.html",
                    controller: "ParkingDetailsController",
                    controllerAs: "vm",
                },
            },
        });
    }
})();
