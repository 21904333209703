(function () {
    'use strict';

    angular
        .module('papp')
        .controller('RoadMaintenanceNotificationController', RoadMaintenanceNotificationController);

    RoadMaintenanceNotificationController.$inject = ['$http', '$state', 'Notification', 'DateUtils', 'TimeUtils', '$uibModal'];

    function RoadMaintenanceNotificationController($http, $state, Notification, DateUtils, TimeUtils, $uibModal) {
        var vm = this;

        vm.loadAll = function () {
            getNotifications()
        };
        vm.loadAll();

        vm.changeDate = changeDate;
        vm.openDatePickerTo = openDatePickerTo;
        vm.sendRoadMaintenance = sendRoadMaintenance;
        vm.showHistoryDetails = showHistoryDetails;
        vm.submit = submit;
        vm.errorMessage = null;


        function openDatePickerTo() {
            vm.isToPickerOpen = true;
        }

        function changeDate() {
            clearErrorMessages();
            vm.errorMessage = TimeUtils.isTimeFormatTooHigh(vm.toHour, vm.toMin)
        }

        function submit() {
            if (!isCorrectInput())
                return;
            var modalInstance = $uibModal.open({
                  animation: true,
                  ariaLabelledBy: 'modal-title',
                  ariaDescribedBy: 'modal-body',
                  templateUrl: 'app/notification/road-maintenance/modal/road-maintenance-modal.html',
                  controller: 'RoadMaintenanceModalController',
                  controllerAs: 'vm',
                  resolve: {
                    message: function () {
                      var message = {}
                      message.validTo = vm.toDate;
                      message.validTo.setHours(vm.toHour);
                      message.validTo.setMinutes(vm.toMin);
                      message.messageNo = vm.messageNo;
                      message.messageEn = vm.messageEn;
                      return message;
                    }
                  }
            });

            modalInstance.result.then(function () {
                    console.log("Confirmed sending notification")
                    sendRoadMaintenance()
                }, function () {
                    console.log("Canceled sending")
                });

        }

        function sendRoadMaintenance() {
            clearErrorMessages();
            vm.errorMessage = "Sender...";
            return $http.post('/api/admin/notification/send', {
                topic: "ROAD_MAINTENANCE",
                topicTypeId: 3,
                validTo: DateUtils.convertLocalDateTimeToServer (vm.toDate, vm.toHour, vm.toMin),
                messageNo: vm.messageNo,
                messageEn: vm.messageEn,
            }).success(function (response) {
                console.log('successfully sent notification!');
                vm.errorMessage = "Varsel sendt!";
                return response;
            }).error(function (e, status) {
                if (status == 403) {
                    vm.errorMessage = "Mangler rettigheter...";
                } else {
                    vm.errorMessage = "Sending feilet!";
                }
                console.log('Sending of notification failed');
            }).finally(function() {
                getNotifications()
            });
        }

        function isCorrectInput() {
            clearErrorMessages()
            vm.errorMessage = TimeUtils.isTimeFormatTooHigh(vm.toHour, vm.toMin)
            if (vm.errorMessage != null)
                return
            vm.errorMessage = TimeUtils.isTimeFormatTooLow(vm.toHour, vm.toMin)
            validateCorrectToDate()
            validateInputNotNull()
            return (vm.errorMessage == null)
        }

        function isCorrectFromDate() {

            var tomorrow = new Date()
            tomorrow.setDate(tomorrow.getDate()+1)
            tomorrow.setSeconds(tomorrow.getSeconds()-10)
            if (fromDate < tomorrow ) {
                vm.errorMessage = "Dieselforbud må settes minst 24 timer før";
                return false
            }
            var inAMonth = new Date()
            inAMonth.setMonth(inAMonth.getMonth()+1)
            if (fromDate > inAMonth ) {
                vm.errorMessage = "Dieselforbud må settes innen en måned";
                return false
            }
            return true;
        }

        function validateCorrectToDate() {
            var now = new Date()
            if (vm.toDate < now ) {
                vm.errorMessage = "Dato må være fram i tid";
            }
        }

        function validateInputNotNull() {
            if (vm.toMin == null || vm.toMin == "") {
                vm.errorMessage = "Mangler tid i minutter";
            }
            if (vm.toHour == null || vm.toHour == "") {
                vm.errorMessage = "Mangler tid i timer";
            }
            if (vm.toDate == null) {
                vm.errorMessage = "Mangler dato";
            }
            if (isTextNotSet(vm.messageNo)) {
                vm.errorMessage = "Mangler tekster på norsk";
                return
            }
            if (isTextNotSet(vm.messageEn)) {
                vm.errorMessage = "Mangler tekster på engelsk";
                return
            }
            if (isTextNotSet(vm.messageNo.pushMessage)) {
                vm.errorMessage = "Mangler pushvarsel-tekst på norsk";
                return
            }
            if (isTextNotSet(vm.messageEn.pushMessage)) {
                vm.errorMessage = "Mangler pushvarsel-tekst på engelsk";
                return
            }
            if (isTextNotSet(vm.messageEn.boardFirstMessage)) {
                vm.errorMessage = "Mangler første avsnitt til varslingssenteret på engelsk";
            }
            if (isTextNotSet(vm.messageNo.boardFirstMessage)) {
                vm.errorMessage = "Mangler første avsnitt til varslingssenteret på norsk";
            }
            if (isTextNotSet(vm.messageEn.boardTitle)) {
                vm.errorMessage = "Mangler tittel til varslingssenteret på engelsk";
            }
            if (isTextNotSet(vm.messageNo.boardTitle)) {
                vm.errorMessage = "Mangler tittel til varslingssenteret på norsk";
            }
            if (isTextNotSet(vm.messageEn.boardUrl)) {
                vm.errorMessage = "Mangler URL til varslingssenteret på engelsk";
            }
            if (isTextNotSet(vm.messageNo.boardUrl)) {
                vm.errorMessage = "Mangler URL til varslingssenteret på norsk";
            }
        }

        function isTextNotSet(text) {
            return (text == null || text == "")
        }

        function clearErrorMessages() {
            vm.errorMessage = null;
        }

        function showHistoryDetails(notification) {
            $state.go('notificationHistoryDetails', {
                id: notification.id
            });
        }

        vm.page = 1

        function getNotifications() {
            Notification.notifications.getNotifications({
                topic: 'ROAD_MAINTENANCE',
                page: vm.page
            }, function (result) {
                vm.notifications = result;
                vm.isFirstPage = vm.page <= 1;

                if (result.page * result.itemsPerPage < result.total) {
                    vm.isLastPage = false;
                }
            });
        }
    }
})();
