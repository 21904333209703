(function () {
    "use strict";

    angular.module("papp").config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider.state("studdedTire", {
            parent: "app",
            url: "/studded-tire?page&plateNumber&email&dateStart",
            data: {
                authorities: ["bilioslo-admin", "bilioslo-admin-lese"],
            },
            views: {
                "content@": {
                    templateUrl: "app/studded-tire/studded-tire.html",
                    controller: "StuddedTireController",
                    controllerAs: "vm",
                },
            },
        });
    }
})();
