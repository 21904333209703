(function () {
    "use strict";
    angular
        .module("papp")
        .constant("VERSION", bioConfig.VERSION)
        .constant("DEBUG_INFO_ENABLED", bioConfig.DEBUG_INFO_ENABLED)
        .constant("URL_BIL_I_OSLO_SERVICE", bioConfig.URL_BIL_I_OSLO_SERVICE)
        .constant("ONELOGIN_CLIENT_ID", bioConfig.ONELOGIN_CLIENT_ID)
        .constant("ONELOGIN_APP_CLIENT_ID", bioConfig.ONELOGIN_APP_CLIENT_ID)
        .constant("ONELOGIN_SUBDOMAIN", bioConfig.ONELOGIN_SUBDOMAIN)
        .constant("ONELOGIN_RESOURCE", bioConfig.ONELOGIN_RESOURCE)
        .constant("ONELOGIN_REFRESH_WHEN_REMAINING", bioConfig.ONELOGIN_REFRESH_WHEN_REMAINING)
        .constant("ONELOGIN_VERIFY_TOKEN_INTERVAL_SECONDS",bioConfig.ONELOGIN_VERIFY_TOKEN_INTERVAL_SECONDS);
})();
