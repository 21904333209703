(function () {
    'use strict';

    angular
        .module('papp')
        .filter('hourToTime', hourToTime);

    function hourToTime() {
        return hourToTimeFilter;

        function hourToTimeFilter(timeString, showSeconds) {
            if (!timeString || 0 === timeString.length) {
                return null;
            }
            var timeStringList = timeString.split(":");
            timeString = "";
            timeStringList.forEach(function (timeElement) {
                if (timeElement !== "00" && timeElement < 10) {
                    timeString += "0" + timeElement + ":";
                } else {
                    timeString += timeElement + ":";
                }
            });
            
            return (showSeconds)?timeString + "00": timeString.substr(0,5);
        }
    }
})();
