(function () {
    "use strict";

    angular.module("papp").config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider.state("transactionDetails", {
            parent: "app",
            url: "/transaction/details/:id",
            data: {
                authorities: ["bilioslo-admin", "bilioslo-admin-lese"],
            },
            views: {
                "content@": {
                    templateUrl:
                        "app/transaction/details/transaction.details.html",
                    controller: "TransactionDetailsController",
                    controllerAs: "vm",
                },
            },
        });
    }
})();
