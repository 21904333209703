(function () {
    'use strict';

    angular
        .module('papp')
        .factory('Message', Message);

    Message.$inject = ['$resource', 'URL_BIL_I_OSLO_SERVICE'];

    function Message($resource, URL_BIL_I_OSLO_SERVICE) {
        var service = {};

        service.messages = $resource(URL_BIL_I_OSLO_SERVICE + 'api/Admin/InformationMessage?page=:page', {}, {
            'query': {method: 'GET', isArray: true},
            'getMessages': {
                method: 'GET', isArray: false,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }            
        });

        return service;
    }
})();
