(function () {
    'use strict';

    angular
        .module('papp')
        .filter('boolToNor', boolToNor);

    function boolToNor() {
        return boolToNorFilter;

        function boolToNorFilter(bool) {
            return bool ? "Ja" : "Nei";
        }
    }
})();
