(function () {
    'use strict';

    angular
        .module('papp')
        .controller('ImportStuddedTireController', ImportStuddedTireController);

    ImportStuddedTireController.$inject = ['$http', 'Price', 'DateUtils', 'URL_BIL_I_OSLO_SERVICE'];

    function ImportStuddedTireController($http, Price, DateUtils, URL_BIL_I_OSLO_SERVICE) {
        var vm = this;
        vm.error = null;
        vm.success = null;
        console.log('ImportStuddedTireController');

        vm.openDatePickerNotHeavy = openDatePickerNotHeavy;
        vm.openDatePickerHeavy = openDatePickerHeavy;

        function openDatePickerNotHeavy(priceId) {
            vm.studdedTirePrices[priceId - 1].isNotHeavyOpen = true;
        }

        function openDatePickerHeavy(priceId) {
            vm.studdedTirePrices[priceId - 1].isHeavyOpen = true;
        }

        vm.loadAll = function () {
            vm.studdedTirePrices = Price.studdedTire.getStuddedTirePrices();
            vm.defaultStuddedTirePrices = Price.studdedTire.getStuddedTirePrices();
        };
        vm.loadAll();

        vm.updatePrices = function (event) {
            console.log('start updating prices...');

            var json = angular.copy(vm.studdedTirePrices);
            var isPositivePrices = null;
            angular.forEach(json, function (value) {
                value.buyFrom = DateUtils.convertLocalDateToServer(value.buyFrom);
                value.buyTo = DateUtils.convertLocalDateToServer(value.buyTo);
                if (value.heavyPrice <= 0 || value.price <= 0) {
                                console.log(value.heavyPrice)
                                console.log(value.price)
                    vm.studdedTireResult = "Noen priser er negative, null eller ikke satt!";
                    console.log('Prices are negative');
                    isPositivePrices = false;
                    return
                }
                if (isPositivePrices == null)
                    isPositivePrices = true;
            });

            if (!isPositivePrices)
                return;

            $http.post(URL_BIL_I_OSLO_SERVICE + 'api/Admin/StuddedTirePrice', json, {})
                .success(function (response) {
                    console.log('success from update studded tire prices');
                    vm.studdedTireResult = "Oppdatering vellykket!";
                    return response;
                }).error(function (e) {
                vm.studdedTireResult = "Oppdatering feilet!";
                console.log('update of studded tire prices failed');
            });
        };
    }
})();
