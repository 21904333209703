(function () {
    "use strict";

    angular.module("papp").config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider.state("import-parking", {
            parent: "price",
            url: "/import-parking",
            data: {
                authorities: ["bilioslo-admin"],
            },
            views: {
                "content@": {
                    templateUrl: "app/price/import-parking/import-parking.html",
                    controller: "ImportParkingController",
                    controllerAs: "vm",
                },
            },
        });
    }
})();
