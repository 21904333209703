(function () {
    'use strict';

    angular
        .module('papp')
        .controller('StuddedTireController', StuddedTireController);

    StuddedTireController.$inject = ['StuddedTire', '$state', '$stateParams', 'DateUtils'];

    function StuddedTireController(StuddedTire, $state, $stateParams, DateUtils) {
        var vm = this;
        var page = 1;
        var plateNumber = null;
        var dateStart = null;
        var email = null;
        
        vm.numberOfPages = 1;
        
        vm.studdedTires = [];

        vm.plateNumberInput = null;
        vm.emailInput = null;
        vm.dateStartInput = null;

        vm.prevPage = prevPage;
        vm.nextPage = nextPage;
        vm.firstPage = firstPage;
        vm.lastPage = lastPage;
        vm.search = search;
        vm.clearSearch = clearSearch;
        vm.showStuddedTire = showStuddedTire;
        vm.openDatePicker = openDatePicker;

        function prevPage() {
            toState(page - 1);
        }

        function nextPage() {
            toState(page + 1);
        }

        function firstPage() {
            toState(0);
        }

        function lastPage() {
            toState(vm.numberOfPages);
        }

        function toState(toPage){
            $state.go('studdedTire', {
                page: toPage,
                plateNumber: plateNumber,
                email: email,
                dateStart: (dateStart ? DateUtils.convertLocalDateToServer(dateStart) : null)
            });
        } 

        function search() {
            $state.go('studdedTire', {
                page: 1,
                plateNumber: (vm.plateNumberInput ? vm.plateNumberInput.toUpperCase() : null),
                email: (vm.emailInput ? vm.emailInput.toLowerCase() : null),
                dateStart: (vm.dateStartInput ? DateUtils.convertLocalDateToServer(vm.dateStartInput) : null)
            });
        }

        function clearSearch() {
            vm.plateNumberInput = null;
            vm.emailInput = null;
            vm.dateStartInput = null;

            $state.go('studdedTire', {
                page: 1,
                plateNumber: null,
                email: null,
                dateStart: null
            });
        }

        function showStuddedTire(studdedTire) {
            $state.go('studdedTireDetails', {
                id: studdedTire.id
            });
        }

        page = parseInt($stateParams.page, 10);
        if (!page) {
            page = 1;
        }

        plateNumber = $stateParams.plateNumber;
        email = $stateParams.email;
        if ($stateParams.dateStart)
            dateStart = new Date($stateParams.dateStart);

        vm.plateNumberInput = plateNumber;
        vm.emailInput = email;
        vm.dateStartInput = dateStart;

        vm.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };

        vm.popupDatePicker = {
            opened: false
        };

        function openDatePicker() {
            vm.popupDatePicker.opened = true;
        }

        StuddedTire.query({
            page: page,
            plateNumber: plateNumber,
            email: email,
            dateStart: (dateStart ? DateUtils.convertLocalDateToServer(dateStart) : null)
        }, function (result) {
            vm.studdedTires = result.studdedTires;
            plateNumber = result.plateNumber;
            email = result.email;
            
            vm.pagination = {
                page: page,
                total: result.total,
                itemsPerPage: result.itemsPerPage
            };

            vm.numberOfPages = result.itemsPerPage != 0 ? Math.ceil(result.total / result.itemsPerPage) : 0;
        });
    }
})(window.angular);
