(function () {
    "use strict";

    angular
        .module("papp")
        .controller("ImportParkingController", ImportParkingController);

    ImportParkingController.$inject = [
        "$scope",
        "$http",
        "DateUtils",
        "ParkingUtils",
        "URL_BIL_I_OSLO_SERVICE",
    ];

    function ImportParkingController(
        $scope,
        $http,
        DateUtils,
        ParkingUtils,
        URL_BIL_I_OSLO_SERVICE
    ) {
        var vm = this;

        vm.error = null;
        vm.success = null;
        vm.parkingTariffs = null;
        vm.dateFrom = new Date(new Date().getFullYear() + 1, 0, 1);
        vm.dateTo = new Date(new Date().getFullYear() + 2, 0, 1);
        vm.openDatePicker = openDatePicker;
        vm.save = save;

        vm.dateOptions = {
            formatYear: "yy",
            startingDay: 1,
        };

        vm.popupDatePicker = {
            opened: false,
        };

        function openDatePicker() {
            vm.popupDatePicker.opened = true;
        }

        function save() {
            return $http
                .post(URL_BIL_I_OSLO_SERVICE + "api/Admin/ParkingPriceGroup", {
                    groups: vm.parkingTariffs,
                    validFrom: DateUtils.convertLocalDateToServer(vm.dateFrom),
                    validTo: DateUtils.convertLocalDateToServer(vm.dateTo),
                })
                .success(function (response) {
                    console.log("success from prices post");
                    vm.result = "Import suksess!";
                    return response;
                })
                .error(function (e) {
                    vm.result = "Import feilet!";
                    console.log("feil import");
                });
        }

        $scope.$watch("uploadFile", function (data) {
            vm.parkingTariffs = null;

            if (!data) return;

            var workbook = XLSX.read(data, { type: "binary" });

            vm.parkingTariffs = [];

            var worksheet = workbook.Sheets["Parkeringbetingelser"];
            var jsonWorksheet = XLSX.utils.sheet_to_json(worksheet);

            angular.forEach(jsonWorksheet, function (value, key) {
                vm.parkingTariffs.push({
                    priceGroup: value["Takstgruppe"],
                    maxParkingTime: ParkingUtils.convertPMaksTid(
                        value["Maks p-tid"]
                    ),
                    maxPrice: value["Maks takst"],
                    mondayStart: value["Avgiftstid start hverdag"],
                    mondayStop: value["Avgiftstid stopp hverdag"],
                    saturdayStart: value["Avgiftstid start lordag"],
                    saturdayStop: value["Avgiftstid stopp lordag"],
                    sundayStart: value["Avgiftstid start sondag"],
                    sundayStop: value["Avgiftstid stopp sondag"],
                    priceThirtyMinutes: value["Takst per 30 min"],
                    priceHour: value["Takst per time"],
                    priceHourSecondary: value["Takst per time sekundær"],
                    priceNinetyMinutes: value["Takst per 1,5 time"],
                    priceTwoHours: value["Takst per 2 timer"],
                    priceThreeHours: value["Takst per 3 timer"],
                    priceFourHours: value["Takst per 4 timer"],
                    priceAdditionalThirtyMinutes:
                        value["Takst per ekstra 30 minutter"],
                    priceDay: value["Takst per døgn"],
                    priceWeek: value["Takst per uke"],
                    priceFourWeeks: value["Takst per 4 uker"],
                    isElectric: value["Elektrisk"],
                    vehiclePriceGroup: 0,
                });
                vm.parkingTariffs.push({
                    priceGroup: value["Takstgruppe"],
                    maxParkingTime: ParkingUtils.convertPMaksTid(
                        value["Maks p-tid"]
                    ),
                    maxPrice: value["Maks takst"],
                    mondayStart: value["Avgiftstid start hverdag"],
                    mondayStop: value["Avgiftstid stopp hverdag"],
                    saturdayStart: value["Avgiftstid start lordag"],
                    saturdayStop: value["Avgiftstid stopp lordag"],
                    sundayStart: value["Avgiftstid start sondag"],
                    sundayStop: value["Avgiftstid stopp sondag"],
                    priceThirtyMinutes: value["Takst per 30 min EL"],
                    priceHour: value["Takst per time EL"],
                    priceHourSecondary: value["Takst per time sekundær EL"],
                    priceNinetyMinutes: value["Takst per 1,5 time EL"],
                    priceTwoHours: value["Takst per 2 timer EL"],
                    priceThreeHours: value["Takst per 3 timer EL"],
                    priceFourHours: value["Takst per 4 timer EL"],
                    priceAdditionalThirtyMinutes:
                        value["Takst per ekstra 30 minutter EL"],
                    priceDay: value["Takst per døgn EL"],
                    priceWeek: value["Takst per uke EL"],
                    priceFourWeeks: value["Takst per 4 uker EL"],
                    isElectric: value["Elektrisk"],
                    vehiclePriceGroup: 1,
                });
                vm.parkingTariffs.push({
                    priceGroup: value["Takstgruppe"],
                    maxParkingTime: ParkingUtils.convertPMaksTid(
                        value["Maks p-tid"]
                    ),
                    maxPrice: value["Maks takst"],
                    mondayStart: value["Avgiftstid start hverdag"],
                    mondayStop: value["Avgiftstid stopp hverdag"],
                    saturdayStart: value["Avgiftstid start lordag"],
                    saturdayStop: value["Avgiftstid stopp lordag"],
                    sundayStart: value["Avgiftstid start sondag"],
                    sundayStop: value["Avgiftstid stopp sondag"],
                    priceThirtyMinutes: value["Takst per 30 min EV"],
                    priceHour: value["Takst per time EV"],
                    priceHourSecondary: value["Takst per time sekundær EV"],
                    priceNinetyMinutes: value["Takst per 1,5 time EV"],
                    priceTwoHours: value["Takst per 2 timer EV"],
                    priceThreeHours: value["Takst per 3 timer EV"],
                    priceFourHours: value["Takst per 4 timer EV"],
                    priceAdditionalThirtyMinutes:
                        value["Takst per ekstra 30 minutter EV"],
                    priceDay: value["Takst per døgn EV"],
                    priceWeek: value["Takst per uke EV"],
                    priceFourWeeks: value["Takst per 4 uker EV"],
                    isElectric: value["Elektrisk"],
                    vehiclePriceGroup: 2,
                });
            });
        });
    }
})();
