(function () {
    'use strict';

    angular
        .module('papp')
        .controller('ParkingPriceDetailsController', ParkingPriceDetailsController);

    ParkingPriceDetailsController.$inject = ['$state', '$stateParams'];

    function ParkingPriceDetailsController($state, $stateParams) {
        var vm = this;

        if (!$stateParams.prices) {
            $state.go('parkingPrice');
        }

        vm.prices = $stateParams.prices;
    }
})();
