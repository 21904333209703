(function () {
    'use strict';

    angular
        .module('papp')
        .filter('platenumberInfo', platenumberInfo);

    function platenumberInfo() {
        return platenumberInfoFilter;

        function platenumberInfoFilter(platenumber) {
            var vehicleWeight = {false:"Lett kjøretøy", true: "Tungt kjøretøy", null: "Vekt ikke satt" };
            var vehicleChargeable = {false:"Ikke ladbart", true: "Ladbart", null:"Lademulighet ikke satt"};
            var chargeableType = {0:"Vanlig", 1:"Hybrid", 2:"Elbil", 3:"El-varebil", null:"Type ladbar ikke satt"};
         
            var output = [];
            output.push( vehicleWeight[platenumber.isHeavy] );
            output.push( vehicleChargeable[platenumber.isChargeable]);
            if (platenumber.isChargeable)
                output.push( chargeableType[platenumber.vehicleType]);
            

            return output.join(", ");
        }
    }
})();