(function() {
    'use strict';

    angular
        .module('papp')
        .factory('Password', Password);

    Password.$inject = ['$resource', 'URL_BIL_I_OSLO_SERVICE'];

    function Password($resource, URL_BIL_I_OSLO_SERVICE) {
        return $resource(URL_BIL_I_OSLO_SERVICE + 'api/Admin/Account/Me/Password', {}, {
            'update': {method: 'PATCH'},
        });
    }
})();
