(function() {
    'use strict';

    angular
        .module('papp')
        .factory('Appversion', Appversion);

    Appversion.$inject = ['$resource', 'DateUtils', 'URL_BIL_I_OSLO_SERVICE'];

    function Appversion ($resource, DateUtils, URL_BIL_I_OSLO_SERVICE) {
        return $resource(URL_BIL_I_OSLO_SERVICE + 'api/Admin/AppVersion', {}, {
            'get': {
                method: 'GET', isArray: true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    angular.forEach(data, function (value) {
                        value.releaseDate = DateUtils.convertLocalDateFromServer(value.releaseDate);
                        value.createdAt = DateUtils.convertLocalDateFromServer(value.createdAt);
                    });
                    return data;
                }
            }});
    }
})();
