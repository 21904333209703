(function() {
    'use strict';

    angular
        .module('papp')
        .filter('studdedTireType', studdedTireType);

    function studdedTireType() {
        return studdedTireTypeFilter;

        function studdedTireTypeFilter (input) {
            if (!input || 0 === input.length) {
                return 'Ukjent';
            }
            if (input === 'SEASON') {
                return "Sesong";
            }
            if (input === 'MONTH') {
                return "Måned";
            }
            if (input === 'DAY') {
                return "Dag";
            }
        }
    }
})();
