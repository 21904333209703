(function () {
    'use strict';

    angular
        .module('papp')
        .factory('NotificationMessage', NotificationMessage);

    NotificationMessage.$inject = ['$resource', '$filter', 'DateUtils'];

    function NotificationMessage($resource, $filter, DateUtils) {
        var service = {};

        var weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        var dieselStartTimeNo = "kl 06.00";
        var dieselEndTimeNo = "kl 22.00";
        var dieselStartTimeEn = "6.00 am";
        var dieselEndTimeEn = "10.00 pm";

        var ifEndDateTextNo = "til";
        var ifEndDateTextEn = "to";
        var ifNoEndDateTextNo = "inntil videre";
        var ifNoEndDateTextEn = "until further notice";

        var afterMessageTextNo = "Les mer";
        var afterMessageTextEn = "More";

        var dateTimeFormatNo = "EEEE dd.MM 'kl' HH.mm";
        var dateTimeFormatEn = "dd.MM h.mm";

        var defaultStartHour = "6";
        var defaultStopHour = "22";
        var defaultMin = "00"
        var defaultDayNo = "xxxdag";
        var defaultDayEn = "Xxxday";


        service.createEndOfMessageStartNo = createEndOfMessageStartNo;
        service.createEndOfMessageStartEn = createEndOfMessageStartEn;
        service.createEndOfMessageStopNo = createEndOfMessageStopNo;
        service.createEndOfMessageStopEn = createEndOfMessageStopEn;

        service.createEndOfBoardFirstMessageStartNo = createEndOfBoardFirstMessageStartNo;
        service.createEndOfBoardFirstMessageStartEn = createEndOfBoardFirstMessageStartEn;
        service.createEndOfBoardFirstMessageStopNo = createEndOfBoardFirstMessageStopNo;
        service.createEndOfBoardFirstMessageStopEn = createEndOfBoardFirstMessageStopEn;

        service.createEndOfComposedMessageStartNo = createEndOfComposedMessageStartNo;
        service.createEndOfComposedMessageStartEn = createEndOfComposedMessageStartEn;
        service.createEndOfComposedMessageStopNo = createEndOfComposedMessageStopNo;
        service.createEndOfComposedMessageStopEn = createEndOfComposedMessageStopEn;


        function createEndOfMessageStartNo(fromDate, fromHour, fromMin) {
            fromHour = (fromHour == null ? defaultStartHour : fromHour)
            fromMin = (fromMin == null ? defaultMin : fromMin)
            return formatDateTimeNo(fromDate, fromHour, fromMin) + ". " + afterMessageTextNo;
        }
        function createEndOfMessageStartEn(fromDate, fromHour, fromMin) {
            fromHour = (fromHour == null ? defaultStartHour : fromHour)
            fromMin = (fromMin == null ? defaultMin : fromMin)
            return formatDateTimeEn(fromDate, fromHour, fromMin) + ". " + afterMessageTextEn;
        }
        function createEndOfMessageStopNo(fromDate, fromHour, fromMin) {
            fromHour = (fromHour == null ? defaultStopHour : fromHour)
            fromMin = (fromMin == null ? defaultMin : fromMin)
            return formatDateTimeNo(fromDate, fromHour, fromMin) + ". " + afterMessageTextNo;
        }
        function createEndOfMessageStopEn(fromDate, fromHour, fromMin) {
            fromHour = (fromHour == null ? defaultStopHour : fromHour)
            fromMin = (fromMin == null ? defaultMin : fromMin)
            return formatDateTimeEn(fromDate, fromHour, fromMin) + ". " + afterMessageTextEn;
        }

        function createEndOfBoardFirstMessageStartNo(fromDate, fromHour, fromMin) {
            fromHour = (fromHour == null ? defaultStartHour : fromHour)
            fromMin = (fromMin == null ? defaultMin : fromMin)
            return "Fra: " + formatDateTimeNo(fromDate, fromHour, fromMin) + " og inntil videre.\n";
        }
        function createEndOfBoardFirstMessageStartEn(fromDate, fromHour, fromMin) {
            fromHour = (fromHour == null ? defaultStartHour : fromHour)
            fromMin = (fromMin == null ? defaultMin : fromMin)
            return "Start: " + formatDateTimeEn(fromDate, fromHour, fromMin) + " until further notice.\n";
        }
        function createEndOfBoardFirstMessageStopNo(fromDate, fromHour, fromMin) {
            fromHour = (fromHour == null ? defaultStopHour : fromHour)
            fromMin = (fromMin == null ? defaultMin : fromMin)
            return formatDateTimeNo(fromDate, fromHour, fromMin) + ".";
        }
        function createEndOfBoardFirstMessageStopEn(fromDate, fromHour, fromMin) {
            fromHour = (fromHour == null ? defaultStopHour : fromHour)
            fromMin = (fromMin == null ? defaultMin : fromMin)
            return formatDateTimeEn(fromDate, fromHour, fromMin) + ".";
        }


        function createEndOfComposedMessageStartNo() {
            return formatDateTimeNo(null, defaultStartHour, defaultMin) + ". " + afterMessageTextNo;
        }
        function createEndOfComposedMessageStartEn() {
            return formatDateTimeEn(null, defaultStartHour, defaultMin) + ". " + afterMessageTextEn;
        }
        function createEndOfComposedMessageStopNo() {
            return formatDateTimeNo(null, defaultStopHour, defaultMin) + ". " + afterMessageTextNo;
        }
        function createEndOfComposedMessageStopEn() {
            return formatDateTimeEn(null, defaultStopHour, defaultMin) + ". " + afterMessageTextEn;
        }

        function formatDateTimeNo(date, hour, min, defaultHour) {
            if (date == null) {
                var tempDate = new Date(2017, 1, 1, hour, min, 0);
                return "xxxdag dd.mm kl " + $filter('date')(tempDate, 'HH:mm');
            }
            date.setHours(hour);
            date.setMinutes(min);
            return $filter('date')(date, dateTimeFormatNo);
        }

        function formatDateTimeEn(date, hour, min, defaultHour) {
            if (date == null) {
                var tempDate = new Date(2017, 1, 1, hour, min, 0);
                return "Xxxday dd.mm " + $filter('date')(tempDate, 'h:mm') + " " + $filter('date')(tempDate, 'a').replace('.','').replace('.','');
            }
            date.setHours(hour);
            date.setMinutes(min);
            return weekdays[date.getDay()] + " " + $filter('date')(date, dateTimeFormatEn) + " " + $filter('date')(date, 'a').replace('.','').replace('.','');
        }

        return service;
    }
})();
