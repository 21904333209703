(function () {
    'use strict';

    angular
        .module('papp')
        .controller('DieselSendModalController', DieselSendModalController);

    DieselSendModalController.$inject = ['$uibModalInstance', 'message', 'Notification'];

    function DieselSendModalController($uibModalInstance, message, Notification) {
        var vm = this;
        vm.message = message;
        vm.lastNotification = Notification.dieselNotificationLast.getLast();

        vm.ok = ok;
        vm.cancel = cancel;

        function ok() {
            $uibModalInstance.close(message);
        }

        function cancel() {
           $uibModalInstance.dismiss();
        }
    }
})();
