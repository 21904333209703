(function () {
    "use strict";

    angular.module("papp").config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider.state("infoMessage", {
            parent: "app",
            url: "/info-message",
            data: {
                authorities: ["bilioslo-admin", "bilioslo-admin-lese"],
            },
            views: {
                "content@": {
                    templateUrl: "app/messages/info-message.html",
                    controller: "InfoMessageController",
                    controllerAs: "vm",
                },
            },
        });
    }
})();
