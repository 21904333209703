(function () {
    'use strict';

    angular
        .module('papp')
        .filter('vehicleGroup', vehicleGroup);

    function vehicleGroup() {
        return vehicleGroupFilter;

        function vehicleGroupFilter(vehiclePriceGroup) {
            var vehiclePriceGroups = {0:"Vanlig", 1:"El-bil", 2:"El-varebil"};
            return vehiclePriceGroups[vehiclePriceGroup];
        }
    }
})();