(function () {
    "use strict";

    angular.module("papp").factory("Auth", Auth);

    Auth.$inject = [
        "$http",
        "$rootScope",
        "$state",
        "$sessionStorage",
        "$q",
        "Principal",
        "AuthServerProvider",
        "ONELOGIN_SUBDOMAIN",
        "ONELOGIN_REFRESH_WHEN_REMAINING",
        "ONELOGIN_VERIFY_TOKEN_INTERVAL_SECONDS",
    ];

    function Auth(
        $http,
        $rootScope,
        $state,
        $sessionStorage,
        $q,
        Principal,
        AuthServerProvider,
        ONELOGIN_SUBDOMAIN,
        ONELOGIN_REFRESH_WHEN_REMAINING,
        ONELOGIN_VERIFY_TOKEN_INTERVAL_SECONDS
    ) {
        var mgr = AuthServerProvider.getUserManager();
        if (intervalId) {
            console.debug("Fant ett intervall med id : " + intervalId);
            clearInterval(intervalId);
            intervalId = undefined;
        }
        var intervalId = setInterval(function () {
            console.debug("Sjekk token etter bruker");
            mgr.getUser().then(function (val) {
                if (val) {
                    console.debug("bruker funnet(" + val + "), sjekk token");
                    refreshTokenIfExpired(val);
                }
            });
        }, ONELOGIN_VERIFY_TOKEN_INTERVAL_SECONDS * 1000);
        console.log("setter intervall for tokensjekk: " + intervalId);

        return {
            authorize: authorize,
            login: login,
            logout: logout,
            loginWithToken: loginWithToken,
            mgr: mgr,
            refreshTokenIfExpired: refreshTokenIfExpired,
        };

        function authorize(force) {
            console.debug("Autoriserer (" + force + ")");
            return Principal.identity(force).then(authThen);

            function authThen(user) {
                var isAuthenticated = Principal.isAuthenticated();

                refreshTokenIfExpired(user);

                if (!isAuthenticated) {
                    mgr.signinRedirectCallback()
                        .then(function (user, bb) {
                            Principal.authenticate(user);
                            window.location.href =
                                window.location.origin + window.location.hash;
                        })
                        .catch(function (err) {
                            if (!err.message.startsWith("No matching state")) {
                                console.log(
                                    "Error completing auth code + pkce flow",
                                    err
                                );
                            }
                            $rootScope.$broadcast(
                                "authenticationComplete",
                                null
                            );
                        });
                }

                whenAuth();
            }

            function whenAuth() {
                var isAuthenticated = Principal.isAuthenticated();

                // an authenticated user can't access to login and register pages
                if (
                    isAuthenticated &&
                    $rootScope.toState.parent === "account" &&
                    $rootScope.toState.name === "login"
                ) {
                    $state.go("home");
                }

                // recover and clear previousState after external login redirect (e.g. oauth2)
                if (
                    isAuthenticated &&
                    !$rootScope.fromState.name &&
                    getPreviousState()
                ) {
                    var previousState = getPreviousState();
                    resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }

                if (
                    $rootScope.toState.data.authorities &&
                    $rootScope.toState.data.authorities.length > 0 &&
                    !Principal.hasAnyAuthority(
                        $rootScope.toState.data.authorities
                    )
                ) {
                    if (isAuthenticated) {
                        // user is signed in but not authorized for desired state
                        $state.go("accessdenied");
                    } else {
                        // user is not authenticated. stow the state they wanted before you
                        // send them to the login service, so you can return them when you're done
                        storePreviousState(
                            $rootScope.toState.name,
                            $rootScope.toStateParams
                        );

                        // now, send them to the signin state so they can log in
                        $state.go("login");
                    }
                }
            }
        }

        function refreshTokenIfExpired(user) {
            if (user)
                console.debug(
                    "Expires: " +
                        user.expires_at +
                        " Time: " +
                        Math.round(Date.now() / 1000) +
                        " Diff: " +
                        (user.expires_at - Math.round(Date.now() / 1000))
                );
            if (
                user &&
                user.expires_at - ONELOGIN_REFRESH_WHEN_REMAINING <
                    Math.round(Date.now() / 1000)
            ) {
                var url = AuthServerProvider.getAuthority() + "/token";

                var serializedData = $.param({
                    grant_type: "refresh_token",
                    client_id: AuthServerProvider.getClientId(),
                    refresh_token: user.refresh_token,
                });
                $http({
                    method: "POST",
                    url: url,
                    data: serializedData,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                }).then(
                    function (response) {
                        AuthServerProvider.updateToken(response.data);
                    },
                    function (error) {
                        console.log("Refresh unsuccessful", error);
                        logout();
                        clearInterval(intervalId);
                    }
                );
            }
        }

        function startLogin() {
            delete $sessionStorage.identity;
            mgr.signinRedirect()
                .then(function () {
                    console.log("startLogin success");
                })
                .catch(function (err) {
                    console.log("startLogin unsuccessful");
                    window.location.href = window.location.origin + "/#login";
                });
        }

        function login(credentials, callback) {
            var cb = callback || angular.noop;
            var deferred = $q.defer();

            AuthServerProvider.login(credentials)
                .then(loginThen)
                .catch(
                    function (err) {
                        this.logout();
                        deferred.reject(err);
                        return cb(err);
                    }.bind(this)
                );

            function loginThen(data) {
                Principal.identity(true).then(function (account) {
                    deferred.resolve(data);
                });
                return cb();
            }

            return deferred.promise;
        }

        function loginWithToken(jwt) {
            return AuthServerProvider.loginWithToken(jwt);
        }

        function logout() {
            console.debug("logger ut i service");
            Principal.identity().then(function (user) {
                AuthServerProvider.logout();
                if (user)
                    window.location.href =
                        "https://" +
                        ONELOGIN_SUBDOMAIN +
                        ".onelogin.com/oidc/2/logout?post_logout_redirect_uri=" +
                        window.location.origin +
                        "&id_token_hint=" +
                        user.id_token;
                else
                    window.location.href =
                        "https://" +
                        ONELOGIN_SUBDOMAIN +
                        ".onelogin.com/oidc/2/logout";
            });
        }

        function getPreviousState() {
            var previousState = $sessionStorage.previousState;
            return previousState;
        }

        function resetPreviousState() {
            delete $sessionStorage.previousState;
        }

        function storePreviousState(previousStateName, previousStateParams) {
            var previousState = {
                name: previousStateName,
                params: previousStateParams,
            };
            $sessionStorage.previousState = previousState;
        }
    }
})();
