(function () {
    "use strict";

    angular.module("papp").factory("Logs", Logs);

    Logs.$inject = ["$resource", "URL_BIL_I_OSLO_SERVICE"];

    function Logs($resource, URL_BIL_I_OSLO_SERVICE) {
        return $resource(
            URL_BIL_I_OSLO_SERVICE +
                "api/Admin/Logs?CustomerId=:CustomerId&FromDate=:FromDate&ToDate=:ToDate",
            { CustomerId: "@id", FromDate: "@FromDate", ToDate: "@ToDate" },
            { getLogs: { method: "GET", isArray: true } }
        );
    }
})();
