(function () {
    'use strict';

    angular
        .module('papp')
        .controller('NotificationHistoryDetailsController', NotificationHistoryDetailsController);

    NotificationHistoryDetailsController.$inject = ['Notification', '$state', '$stateParams', 'DateUtils'];

    function NotificationHistoryDetailsController(Notification, $state, $stateParams, DateUtils) {
        var vm = this;
        vm.dateStart = null;
        vm.notification = {};
        vm.load = load;

        vm.load($stateParams.id);

        vm.dateOptions = {
            formatYear: 'yy',
            startingDay: 1
        };

        function load(id) {
            Notification.notification.getSingle({id: id}, function (result) {
                vm.notification = result;
            });
        }
    }
})();
