(function () {
    "use strict";

    angular.module("papp").controller("NavbarController", NavbarController);

    NavbarController.$inject = ["$scope", "$state", "Auth", "Principal"];

    function NavbarController($scope, $state, Auth, Principal) {
        var vm = this;

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        setAccount();

        $scope.$on("authenticationComplete", function (evt, user) {
            setAccount(user);
        });

        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;

        function setAccount(user) {
            if (user) {
                vm.user = user.profile.sub;
                vm.userName = user.profile.name;
            } else {
                Principal.identity().then(function (data) {
                    if (data && data.profile) {
                        vm.user = data.profile.sub;
                        vm.userName = data.profile.name;
                    }
                });
            }
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go("login");
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }
    }
})();
