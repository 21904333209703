(function () {
    "use strict";

    angular.module("papp").config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider.state("parkingPriceDetails", {
            parent: "parkingPrice",
            url: "/price/parking/details",
            params: {
                prices: null,
            },
            data: {
                authorities: ["bilioslo-admin", "bilioslo-admin-lese"],
            },
            views: {
                "content@": {
                    templateUrl:
                        "app/price/parking/details/parking-price.details.html",
                    controller: "ParkingPriceDetailsController",
                    controllerAs: "vm",
                },
            },
        });
    }
})();
