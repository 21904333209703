(function () {
    'use strict';

    angular
        .module('papp')
        .factory('AdminUser', AdminUser);

    AdminUser.$inject = ['$resource', 'URL_BIL_I_OSLO_SERVICE'];

    function AdminUser($resource, URL_BIL_I_OSLO_SERVICE) {
        return $resource(URL_BIL_I_OSLO_SERVICE + 'api/Admin/Account/:id', {id: '@id'}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': {method: 'POST'},
            'update': {method: 'PUT'},
            'delete': {method: 'DELETE'}
        });
    }
})();
