(function () {
    "use strict";

    angular.module("papp").config(stateConfig);

    stateConfig.$inject = ["$stateProvider"];

    function stateConfig($stateProvider) {
        $stateProvider.state("dieselEditNotification", {
            parent: "Notification",
            url: "/notification/diesel-edit",
            data: {
                authorities: ["bilioslo-admin-dieselvarsel"],
            },
            views: {
                "content@": {
                    templateUrl:
                        "app/notification/diesel-edit/diesel-edit.html",
                    controller: "DieselEditNotificationController",
                    controllerAs: "vm",
                },
            },
        });
    }
})();
