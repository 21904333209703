(function () {
    'use strict';

    angular
        .module('papp')
        .factory('PspJob', PspJob);

    PspJob.$inject = ['$resource', 'URL_BIL_I_OSLO_SERVICE'];

    function PspJob($resource, URL_BIL_I_OSLO_SERVICE) {
        return $resource(URL_BIL_I_OSLO_SERVICE + 'api/Admin/PspJob/:id', {}, {
            'query': {method: 'GET', isArray: false},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });
    }
})();
