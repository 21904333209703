(function () {
    'use strict';

    angular
        .module('papp')
        .controller('TransactionDetailsController', TransactionDetailsController);

    TransactionDetailsController.$inject = ['PspJob', 'Transaction', '$stateParams'];

    function TransactionDetailsController(PspJob, Transaction, $stateParams) {
        var vm = this;
        var transactionPage = 1;

        vm.isFirstTransactionPage = true;
        vm.isLastTransactionPage = true;

        vm.load = load;
        vm.prevTransactionPage = prevTransactionPage;
        vm.nextTransactionPage = nextTransactionPage;

        vm.load($stateParams.id);

        function prevTransactionPage() {
            transactionPage = transactionPage - 1;
            queryTransactions();
        }

        function nextTransactionPage() {
            transactionPage = transactionPage + 1;
            queryTransactions();
        }

        function queryTransactions() {
            Transaction.query({
                jobId: $stateParams.id,
                page: transactionPage
            }, function (result) {
                vm.transactions = result.transactions;
                vm.isFirstTransactionPage = transactionPage <= 1;
                vm.isLastTransactionPage = result.page * result.itemsPerPage >= result.total;
            });
        }

        function load(id) {
            PspJob.get({id: id}, function(result) {
                vm.job = result;
            });

            queryTransactions();
        }
    }
})();
