(function () {
    'use strict';

    angular
        .module('papp')
        .controller('ParkingPriceController', ParkingPriceController);

    ParkingPriceController.$inject = ['$state', 'Price'];

    function ParkingPriceController($state, Price) {
        var vm = this;

        Price.parking.getPrices(function (result) {
            if (result.prices.length === 1) {
                $state.go('parkingPriceDetails', {
                    prices: result.prices[0]
                });
            } else {
                vm.prices = result.prices;
            }
        });
    }
})();
