(function () {
    'use strict';

    angular
        .module('papp')
        .factory('Notification', Notification);

    Notification.$inject = ['$resource', 'URL_BIL_I_OSLO_SERVICE'];

    function Notification($resource, URL_BIL_I_OSLO_SERVICE) {
        var service = {};

        service.dieselMessages = $resource(URL_BIL_I_OSLO_SERVICE + 'api/Admin/SimpleNotificationService/Diesel/DefaultMessage', {}, {
            'query': {method: 'GET', isArray: true},
            'getDieselMessages': {
                method: 'GET', isArray: true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': {method: 'POST'},
            'update': {method: 'PUT'},
            'delete': {method: 'DELETE'}
        });

        service.notifications = $resource(URL_BIL_I_OSLO_SERVICE + 'api/Admin/SimpleNotificationService/History', {}, {
            'query': {method: 'GET', isArray: true},
            'getNotifications': {
                method: 'GET', isArray: true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': {method: 'POST'},
            'update': {method: 'PUT'},
            'delete': {method: 'DELETE'}
        });

        service.notification = $resource('api/admin/notification/history/:id', {}, {
            'query': {method: 'GET', isArray: false},
            'getSingle': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });

        service.dieselNotificationLast = $resource(URL_BIL_I_OSLO_SERVICE + 'api/Admin/SimpleNotificationService/History/Last', {
                topic:'DIESEL'
            }, {
            'query': {method: 'GET', isArray: false},
            'getLast': {
                method: 'GET', isArray: false,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': {method: 'POST'},
            'update': {method: 'PUT'},
            'delete': {method: 'DELETE'}
        });

        service.activeDieselBan = $resource(URL_BIL_I_OSLO_SERVICE + 'api/Admin/SimpleNotificationService/Diesel', {}, {
            'query': {method: 'GET', isArray: false},
            'getActiveDieselBan': {
                method: 'GET', isArray: false,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': {method: 'POST'},
            'update': {method: 'PUT'},
            'delete': {method: 'DELETE'}
        });

        return service;
    }
})();
